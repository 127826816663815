import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/services/form/form.service';
import { MetatagsService } from 'src/app/services/metatags/metatags.service';
import { RegisterService } from 'src/app/services/register/register.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class FormComponent implements OnInit {

  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;

  isEditable = false;
  message
  state = true
  stateTwo = false;
  loader = false;
  horliInfo
  currentStep
  ruta = this.router.url

  constructor(
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private registerService: RegisterService,
    private router: Router,
    public formService: FormService,
    public metatags: MetatagsService,

  ) {

    if (this.router.url != "/register") {
      this.state = false
      this.loader = true
      let idUser = this.route.snapshot.paramMap.get("user");
      this.registerService.checkUserPay(idUser).then(res => {
        if (res == false) {
          this.userNotFound(idUser)
        } else if (res.payIt == false) {
          this.horliNotPay(res);
        } else {
          this.continueToForm(res, idUser);
        }
      })
    }
    
    metatags.updateMetaTags(this.ruta);
  }

  ngOnInit(): void {

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.fourthFormGroup = this._formBuilder.group({
      fourthCtrl: ['', Validators.required]
    });
    this.fifthFormGroup = this._formBuilder.group({
      fifthCtrl: ['', Validators.required]
    });

  }

  go(n) {
    for (let i = 0; i <= n; i++) {
      this.stepper.selectedIndex = i;
    }
    this.stepper.selected.state = 'edit';
  }

  nextStep(step) {

    switch (step) {
      case 'one':
        this.stepper.selected.completed = true
        this.stateTwo = true
        break;
      case 'next':
        this.stepper.selected.completed = true
        break;
    }
    this.stepper.next();
  }

  prevStep(step) {
    switch (step) {
      case 'two':
        this.stepper.previous();
        setTimeout(() => {
          this.stepper.selected.completed = true
        }, 1000);
        break;
      default:
        this.stepper.previous();
        this.stepper.selected.completed = true
        break;
    }
  }

  userNotFound(id) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: "No se ha encontrado el HOLRI " + id,
      confirmButtonText: 'Entendido',
      customClass: {
        container: "modal-error"
      }
    }).then(() => {
      this.loader = false;
      this.state = true;
      this.router.navigateByUrl('/register');
    })
  }

  horliNotPay(res) {
    this.registerService.setUserInfo(res)
    this.registerService.setReActive(true)
    this.horliInfo = res;
    this.state = true;
    this.loader = false;
    this.go(1);
  }

  continueToForm(res, idUser) {
    var confirm = this.registerService.getStatusComplete
    if (res.complete == true) {
      this.router.navigateByUrl('/my-networking')
    } else if (confirm == true) {
      this.registerService.getCollHorlies(idUser).subscribe((data: any) => {
        this.registerService.setUserInfo(data)
        this.horliInfo = data;
        this.currentStep = data.currentStep
        this.formService.setStep(data.currentStep);
        setTimeout(() => {
          this.go(data.currentStep - 1);
          this.loader = false;
        }, 250);
      })
    } else {
      this.router.navigateByUrl('/' + idUser + '/confirm')
    }
  }

}
