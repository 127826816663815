<section id="form-page">
    <div>
        <img src="assets/img/networking.png" alt="" class="networking">
    </div>
    <mat-horizontal-stepper linear #stepper>
        <ng-template matStepperIcon="edit" let-index="index">
            {{index+1}}
        </ng-template>

        <ng-template matStepperIcon="skipped">
            <mat-icon>pause</mat-icon>
        </ng-template>

        <ng-template matStepperIcon="number" let-index="index">
            {{index+1}}
        </ng-template>

        <mat-step [aria-labelledby]="state ? null : 'block-step'">
            <app-step-one (next)="nextStep($event)"></app-step-one>
        </mat-step>

        <mat-step [aria-labelledby]="stateTwo ? null : 'block-step'">
            <app-step-two (next)="nextStep($event)" (back)="prevStep($event)"></app-step-two>
        </mat-step>

        <mat-step 
            [completed]="currentStep >= 3 ? true : false"  
            [aria-label]="currentStep == 3 ? 'edit' : null"
            [aria-labelledby]="currentStep >= 3 ? null : 'block-step'">
            <app-step-three (next)="nextStep($event)"></app-step-three>
        </mat-step>

        <mat-step 
            [completed]="currentStep >= 4 ? true : false" 
            [aria-label]="currentStep == 4 ? 'edit' : null"
            [aria-labelledby]="currentStep >= 4 ? null : 'block-step'">
            <app-step-four (back)="prevStep($event)" (next)="nextStep($event)"></app-step-four>
        </mat-step>

        <mat-step 
            [completed]="currentStep >= 5 ? true : false"  
            [aria-label]="currentStep == 5 ? 'edit' : null"
            [aria-labelledby]="currentStep >= 5 ? null : 'block-step'">
            <app-step-five (back)="prevStep($event)" (next)="nextStep($event)"></app-step-five>
        </mat-step>

        <mat-step
            [completed]="currentStep >= 6 ? true : false"    
            [aria-label]="currentStep == 6 ? 'edit' : null"
            [aria-labelledby]="currentStep >= 6 ? null : 'block-step'">
            <app-step-six (back)="prevStep($event)"></app-step-six>
        </mat-step>

    </mat-horizontal-stepper>
    <ng-container *ngIf="loader">
        <div class="loader">
            <div class="spinner-border text-danger" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-container>
</section>