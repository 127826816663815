import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MetatagsService } from 'src/app/services/metatags/metatags.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  ruta = this.router.url
  constructor(
    private router: Router,
    public metatags: MetatagsService) { 
    metatags.updateMetaTags(this.ruta);
  }

  ngOnInit(): void {
  }

}
