<form [formGroup]="personalForm" style="overflow-y: auto;">
    <section *ngIf="showForm" [ngClass]=" activeClick ? 'activeClick' : 'good'">
        <div class="container">
            <h1>Información Personal</h1>
            <div class="row payment">
                <div class="col-md-6 card-info">
                    <div class="form">
                        <div class="row">
                            <div class="form-group col-md-12"
                                [ngClass]="hasError('aboutMe', 'required') ? 'error' : 'good'">
                                <label for="aboutMe" class="col-form-label">SOBRE MÍ </label>
                                <div>
                                    <textarea formControlName="aboutMe" type="text" class="form-control" id="aboutMe"
                                        rows="6"></textarea>
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <hr>
                                <div class="row mb-3">
                                    <div class="col-9"
                                        [ngClass]="!personalForm.controls['skills'].valid ? 'error' : 'good'">
                                        <label class="col-form-label pb-0">HABILIDADES </label><i
                                            class="fa fa-question-circle" matTooltip="¿Necesitas ayuda?"
                                            matTooltipPosition="above"
                                            (click)="openTooltipModal(tooltipModal, 'habilities')"
                                            style="color: #5C70DD; margin-left: 1rem; cursor: pointer;"></i><br>
                                        <span class="custom-span">*Mínimo 3</span>
                                    </div>
                                    <div class="col-3 d-flex align-items-center">
                                        <button type="button" class="btn btn-danger tiny-button" (click)="addSkill()">
                                            Añadir +
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <div formArrayName="skills">
                                        <div *ngFor="let item of getSkillsArray.controls; let pointIndex = index"
                                            [formGroupName]="pointIndex" class="row">
                                            <div class="input-group mb-3" *ngIf="pointIndex > 2"
                                                [ngClass]="!this.getSkillsArray.at(pointIndex).valid ? 'error' : 'good'">
                                                <input type="text" class="form-control" formControlName="skill">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-danger btn-delete-custom"
                                                        type="button" id="skill_{{pointIndex}}"
                                                        (click)="removeParam(pointIndex, 'skills')">Borrar</button>
                                                </div>
                                            </div>
                                            <!-- [ngClass]="!this.getSkillsArray.at(pointIndex).valid ? 'error' : 'good'" -->
                                            <div class="input-group mb-3" *ngIf="pointIndex >= 0 && pointIndex < 3"
                                                [ngClass]="emptyInputSkill[pointIndex] ?  'error'+pointIndex : 'good' ">
                                                <input type="text" class="form-control" formControlName="skill"
                                                    (keypress)="cambioError(pointIndex,'skills')">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 photo">
                    <div class="form-group col-md-12" [ngClass]="hasError('userPhoto', 'required') ? 'error' : 'good'">
                        <label class="col-form-label">MI FOTO </label>
                        <!-- *ngIf="horliAux?.userPhoto == undefined " -->
                        <div class="custom-dropzone d-flex align-items-center" ngx-dropzone [accept]="'image/*'"
                            [multiple]=false [maxFileSize]="8000000" (click)="file.click()"
                            (drop)="fileChangeEvent($event)" [disableClick]="true">
                            <ngx-dropzone-label>
                                <div>
                                    <i class="fa fa-user-circle icon-user" aria-hidden="true"></i>
                                    <h4 style="font-size: 1rem; padding: 0 30px;">ADJUNTA TU FOTOGRAFÍA PROFESIONAL AQUÍ
                                    </h4>
                                </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-image-preview class="user-drop" ngProjectAs="ngx-dropzone-preview"
                                *ngFor="let f of user" [file]="f" [removable]="true" (removed)="onRemoveUserPhoto(f)"
                                style="margin: auto !important;">
                            </ngx-dropzone-image-preview>
                        </div>
                        <input type="file" class="file-input" accept="image/*" (change)="fileChangeEvent($event);"
                            #file />
                    </div>
                    <div class="form-group col-md-12" [ngClass]="hasError('interests', 'required') ? 'error' : 'good'">
                        <hr>
                        <label for="interests" class="col-form-label">INTERESES </label> <i
                            matTooltip="¿Necesitas ayuda?" matTooltipPosition="above" class="fa fa-question-circle"
                            (click)="openTooltipModal(tooltipModal, 'interest')"
                            style="color: #5C70DD; margin-left: 1rem; cursor: pointer;"></i>
                        <div>
                            <textarea formControlName="interests" type="text" class="form-control" id="interests"
                                rows="4"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <div>
                    <button style="display: none;" type="button" class="button-custom"><i class="fa fa-long-arrow-left"
                            aria-hidden="true"></i>
                        Volver</button>
                </div>
                <div>
                    <button type="button" (click)="saveChanges()" class="btn btn-link">Guardar cambios</button>
                    <button (click)="toggleForm('next')" type="button" class="button-custom">Siguiente <i
                            class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                    <!-- [disabled]="!contactForm.valid" -->
                </div>
            </div>
        </div>
        <ng-container *ngIf="loader">
            <div class="loader">
                <div class="spinner-border text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
    </section>

    <section *ngIf="!showForm" [ngClass]=" activeClickSecond ? 'activeClick' : 'good'">
        <div class="container">
            <h1>Información Personal</h1>
            <div class="row payment">
                <div class="col-md-6 card-info">
                    <div class="form">
                        <div class="row">
                            <div class="form-group col-md-12">
                                <div class="row mb-3">
                                    <div class="col-9"
                                        [ngClass]="!personalForm.controls['hobbies'].valid ? 'error' : 'good'">
                                        <label class="col-form-label pb-0">PASATIEMPOS </label><i
                                            class="fa fa-question-circle" matTooltip="¿Necesitas ayuda?"
                                            matTooltipPosition="above"
                                            (click)="openTooltipModal(tooltipModal, 'hobbies')"
                                            style="color: #5C70DD; margin-left: 1rem; cursor: pointer;"></i><br>
                                        <span class="custom-span">*Mínimo 3</span>
                                    </div>
                                    <div class="col-3 d-flex align-items-center">
                                        <button type="button" class="btn btn-danger tiny-button" (click)="addHobbie()">
                                            Añadir +
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <div formArrayName="hobbies">
                                        <div *ngFor="let item of getHobbiesArray.controls; let pointIndex = index"
                                            [formGroupName]="pointIndex" class="row">
                                            <div class="input-group mb-3" *ngIf="pointIndex > 2">
                                                <input type="text" class="form-control" formControlName="hobbie">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-danger btn-delete-custom"
                                                        type="button" id="hobbie_{{pointIndex}}"
                                                        (click)="removeParam(pointIndex,'hobbies')">Borrar</button>
                                                </div>
                                            </div>
                                            <!-- !this.getHobbiesArray.at(pointIndex).valid ? 'error' : 'good' -->
                                            <div class="input-group mb-3" *ngIf="pointIndex >= 0 && pointIndex < 3"
                                                [ngClass]="emptyInput[pointIndex] ?  'error'+pointIndex : 'good' ">
                                                <input type="text" class="form-control" formControlName="hobbie"
                                                    (keypress)="cambioError(pointIndex,'hobbies')">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-12">
                                <hr>
                                <div class="row mb-3">
                                    <div class="col-9"
                                        [ngClass]="!personalForm.controls['cities'].valid ? 'error' : 'good'">
                                        <label class="col-form-label pb-0">CIUDADES EN LAS QUE HA VIVIDO </label> <i
                                            class="fa fa-question-circle" matTooltip="¿Necesitas ayuda?"
                                            matTooltipPosition="above"
                                            (click)="openTooltipModal(tooltipModal, 'cities')"
                                            style="color: #5C70DD; margin-left: 1rem; cursor: pointer;"></i><br>
                                    </div>
                                    <div class="col-3 d-flex align-items-center">
                                        <button type="button" class="btn btn-danger tiny-button" (click)="addCity()">
                                            Añadir +
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <div formArrayName="cities">
                                        <div *ngFor="let item of getCitiesArray.controls; let pointIndex = index"
                                            [formGroupName]="pointIndex" class="row">
                                            <div class="input-group mb-3" *ngIf="pointIndex > 2"
                                                [ngClass]="!this.getCitiesArray.at(pointIndex).valid ? 'error' : 'good'">
                                                <input type="text" class="form-control" formControlName="city">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-danger btn-delete-custom"
                                                        type="button" id="city_{{pointIndex}}"
                                                        (click)="removeParam(pointIndex,'cities')">Borrar</button>
                                                </div>
                                            </div>
                                            <div class="input-group mb-3" *ngIf="pointIndex >= 0  && pointIndex < 3"
                                                [ngClass]="emptyInputCity == pointIndex ?  'error1': 'good' ">
                                                <input type="text" class="form-control" formControlName="city"
                                                    (keypress)="cambioError(pointIndex,'cities')">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 photo">
                    <!-- *ngIf="(horliAux?.hobbiesPhotos == undefined || horliAux?.hobbiesPhotos?.length == 0 )" -->
                    <div class="form-group col-md-12"
                        [ngClass]="hasError('hobbiesPhotos', 'required') ? 'error' : 'good'">
                        <label class="col-form-label pb-0">MIS FOTOGRAFÍAS</label><br>
                        <span class="custom-span">*Mínimo 1, Máximo 4</span>
                        <div class="custom-dropzone-hobbies d-flex align-items-center" ngx-dropzone [accept]="'image/*'"
                            [maxFileSize]="8000000" (change)="onSelectHobbiesPhotos($event)">
                            <ngx-dropzone-label>
                                <div>
                                    <i class="fa fa-image icon-hobbie" aria-hidden="true"></i>
                                    <h4 style="font-size: 1rem; padding: 0 30px;">ADJUNTA TUS FOTOGRAFÍAS, DE TUS
                                        PASATIEMPOS, INTERESES O CIUDADES EN LAS QUE HAS VIVIDO</h4>
                                </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of hobbies"
                                [file]="f" [removable]="true" (removed)="onRemoveHobbiesPhotos(f)"
                                style="margin: auto !important;">
                            </ngx-dropzone-image-preview>
                        </div>
                    </div>
                    <!-- <div class="container-hobbies col-12 row d-flex justify-content-around" *ngIf="horliAux?.hobbiesPhotos?.length > 0">
                    <div class="col-lg-5" *ngFor="let hobbiePhoto of horliAux?.hobbiesPhotos">
                        <img [src]="hobbiePhoto.url" class="img-hobbie" alt="">
                    </div>
                    
                </div> -->
                </div>
            </div>
            <div class="buttons">
                <div>
                    <button (click)="toggleForm('back')" type="button" class="button-custom"><i
                            class="fa fa-long-arrow-left" aria-hidden="true"></i>
                        Volver </button>
                </div>

                <div>
                    <button type="button" (click)="saveChanges()" class="btn btn-link">Guardar cambios</button>
                    <button (click)="onSubmit()" type="button" class="button-custom">Siguiente <i
                            class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                    <!-- [disabled]="!personalForm.valid" -->
                </div>
            </div>
        </div>
        <ng-container *ngIf="loader">
            <div class="loader">
                <div class="spinner-border text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
    </section>
</form>

<ng-template #modalPhoto>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Ajustar imagen</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="20 / 23"
            format="jpg" [hideResizeSquares]="true" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" [transform]="transform">
        </image-cropper>
        <input type="range" min="0.5" max="2" step="0.1" value="1" class="range-zoom"
            (input)="valueChanged($event.target.value)">
        <div>
            <img [src]="croppedImage" class="img-user" alt="">
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="modalRef.hide()" class="btn btn-success">Aceptar</button>
        <button (click)="closeModal(1);" class="btn btn-danger">Cancelar</button>
    </div>
</ng-template>

<ng-template #tooltipModal>
    <ng-container [ngSwitch]="tooltip">
        <ng-container *ngSwitchCase="'habilities'">
            <div class="modal-body tooltip-modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-12 col-lg-8">
                            <img src="../../../assets/img/tooltips/desktop/habilidades.png"
                                class="tooltip-img desktop-img" alt="">
                            <img src="../../../assets/img/tooltips/movil/Habilidades.png" class="tooltip-img mobile-img"
                                alt="">
                        </div>
                        <div class="col-md-12 col-lg-4 tooltip-info">
                            <!-- <span aria-hidden="true" class="pull-right btn-close-modal" (click)="modalRef.hide()">&times;</span> -->
                            <img src="../../../assets/img/cancel.svg" class="pull-right btn-close-modal"
                                (click)="modalRef.hide()" alt="">
                            <p class="info-title">Habilidades</p>
                            <p>Mencionar tus habilidades principales como persona y profesional, te ayudará a generar
                                una excelente primera impresión con los demás networkers. </p>
                            <p> De igual forma, les facilitará a ellos a conocerte un poco más, desde el primer
                                acercamiento.</p>
                            <p class="example-text"><span>Ejemplo actual basado en:</span> "Obi Wan Kenobi, Maestro
                                Jedi"</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button (click)="modalRef.hide()" class="btn btn-success">Entendido</button>
            </div> -->
        </ng-container>

        <ng-container *ngSwitchCase="'interest'">
            <div class="modal-body tooltip-modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-12 col-lg-7">
                            <img src="../../../assets/img/tooltips/desktop/Intereses.png"
                                class="tooltip-img desktop-img" alt="">
                            <img src="../../../assets/img/tooltips/movil/Intereses.png" class="tooltip-img mobile-img"
                                alt="">
                        </div>
                        <div class="col-md-12 col-lg-5 tooltip-info">
                            <!-- <span aria-hidden="true" class="pull-right btn-close-modal" (click)="modalRef.hide()">&times;</span> -->
                            <img src="../../../assets/img/cancel.svg" class="pull-right btn-close-modal"
                                (click)="modalRef.hide()" alt="">
                            <p class="info-title">Intereses</p>
                            <p>Platicar sobre tus intereses, ciudades donde has vivido y el resto de la información
                                personal, facilita conectar con otros networkers, ya que, por el simple hecho de saber
                                más de ti, confiarán más en ti.</p>
                            <p>Además, toda esta información es un excelente
                                “rompehielos” para iniciar una conversación con otro networker.</p>
                            <p class="example-text"><span>Ejemplo actual basado en:</span> "Obi Wan Kenobi, Maestro
                                Jedi"</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button (click)="modalRef.hide()" class="btn btn-success">Entendido</button>
            </div> -->
        </ng-container>

        <ng-container *ngSwitchCase="'hobbies'">
            <div class="modal-body tooltip-modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-12 col-lg-7">
                            <img src="../../../assets/img/tooltips/desktop/Pasatiempos.png"
                                class="tooltip-img desktop-img" alt="">
                            <img src="../../../assets/img/tooltips/movil/Pasatiempos.png" class="tooltip-img mobile-img"
                                alt="">
                        </div>
                        <div class="col-md-12 col-lg-5 tooltip-info">
                            <!-- <span aria-hidden="true" class="pull-right btn-close-modal" (click)="modalRef.hide()">&times;</span> -->
                            <img src="../../../assets/img/cancel.svg" class="pull-right btn-close-modal"
                                (click)="modalRef.hide()" alt="">
                            <p class="info-title">Pasatiempos</p>
                            <p>En el networking se busca generar confianza como persona, por lo que es importante
                                compartir además del ámbito empresarial y/o profesional, el personal.</p>
                            <p>Mencionar tus pasatiempos o las actividades que realizas en tu tiempo libre es
                                importante para conocer gustos, preferencias y a ti como persona.</p>
                            <p>Además de que refuerza una posible conexión encontrando puntos en común con otros
                                networkers, haciendo más fácil iniciar una conversación fomentando la confianza.
                                Todos estos son factores importantes para tener una excelente sesión de networking.</p>
                            <p class="example-text"><span>Ejemplo actual basado en:</span> "Obi Wan Kenobi, Maestro
                                Jedi"</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button (click)="modalRef.hide()" class="btn btn-success">Entendido</button>
            </div> -->
        </ng-container>

        <ng-container *ngSwitchCase="'cities'">
            <div class="modal-body tooltip-modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-12 col-lg-7">
                            <img src="../../../assets/img/tooltips/desktop/Ciudades.png" class="tooltip-img desktop-img"
                                alt="">
                            <img src="../../../assets/img/tooltips/movil/Ciudades.png" class="tooltip-img mobile-img"
                                alt="">
                        </div>
                        <div class="col-md-12 col-lg-5 tooltip-info">
                            <!-- <span aria-hidden="true" class="pull-right btn-close-modal" (click)="modalRef.hide()">&times;</span> -->
                            <img src="../../../assets/img/cancel.svg" class="pull-right btn-close-modal"
                                (click)="modalRef.hide()" alt="">
                            <p class="info-title">Ciudades</p>
                            <p>Platicar sobre tus intereses, ciudades donde has vivido y el resto de la información
                                personal, facilita conectar con otros networkers, ya que, por el simple hecho de saber
                                más de ti, confiarán más en ti.</p>
                            <p>Además, toda esta información es un excelente
                                “rompehielos” para iniciar una conversación con otro networker.</p>
                            <p class="example-text"><span>Ejemplo actual basado en:</span> "Obi Wan Kenobi, Maestro
                                Jedi"</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button (click)="modalRef.hide()" class="btn btn-success">Entendido</button>
            </div> -->
        </ng-container>
    </ng-container>
</ng-template>