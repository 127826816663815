import { Component, Input, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
  selector: 'app-myself',
  templateUrl: './myself.component.html',
  styleUrls: ['./myself.component.scss']
})
export class MyselfComponent implements OnInit {

  @Input() horli: any;
  public myOptions: NgxMasonryOptions = {
    gutter: 10
  };
  
  constructor() { }

  ngOnInit(): void {
  }

}
