import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  userInfo
  formCompleted = false;
  reActive = false

  constructor(public afs: AngularFirestore) { }

  //USER INFO GET AND SET
  get getUserInfo() {
    return this.userInfo;
  }
  setUserInfo(value) {
    this.userInfo = value;
  }
  //COMPLETE CONFIRM MAIL GET AND SET
  get getStatusComplete() {
    return this.formCompleted;
  }
  setStatusComplete(value) {
    this.formCompleted = value;
  }
  //REACTIVE GET AND SET
  get getReActive() {
    return this.reActive;
  }
  setReActive(value) {
    this.reActive = value;
  }

  addNewUser() {
    return new Promise<any>((resolve, reject) => {
      var preId = this.userInfo.first_name.split(" ")[0]+this.userInfo.last_name.split(" ")[0]
      var linkId = preId.normalize('NFD').replace(/[^-A-Za-z0-9]+/g,'').toLowerCase();
      var day = new Date()
      var end = new Date(day.getFullYear() + 1, day.getMonth(), day.getDate())
      this.checkDocExist(linkId).then(resp => {
        this.afs.collection("horlies").doc(resp.id).set({
          name: this.userInfo.first_name,
          lastName: this.userInfo.last_name,
          job: this.userInfo.job,
          email: this.userInfo.email,
          phone: this.userInfo.phone,
          payIt: true,
          complete: false,
          consecutive: resp.int,
          link: document.location.origin + "/" + resp.id,
          buyDate: day,
          buyDateISO: day.toISOString(),
          endDate: end,
          endDateISO: end.toISOString(),
          currentStep: 3
        })
        let updateUser = {
          name: this.userInfo.name,
          job: this.userInfo.job,
          email: this.userInfo.email,
          phone: this.userInfo.phone,
          linkId: resp.id,
          url: document.location.origin + "/" + resp.id
        }
        this.setUserInfo(updateUser)
      }).then(() => {
        resolve(this.userInfo)
      })
    })
  }

  reActiveDoc(id) {
    return new Promise<void>((resolve, reject) => {
      var day = new Date()
      var end = new Date(day.getFullYear() + 1, day.getMonth(), day.getDate())
      this.afs.collection("horlies").doc(id).update({
        payIt: true,
        buyDate: day,
        buyDateISO: day.toISOString(),
        endDate: end,
        endDateISO: end.toISOString()
      }).then(() => {
        resolve()
      })
    })
  }

  checkDocExist(id) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("horlies").doc(id).ref.get()
        .then((data: any) => {
          if (data.exists) {
            this.afs.collection("horlies").doc(id).update({
              consecutive: data.data().consecutive + 1
            })
            var newId = (id + (data.data().consecutive + 1)).toString()
            let info = { id: newId, int: data.data().consecutive + 1 }
            resolve(info)
          } else {
            let info = { id: id, int: 1 }
            resolve(info)
          }
        });
    })
  }

  checkUserPay(id) {
    return new Promise<any>((resolve, reject) => {
      this.afs.collection("horlies").doc(id).ref.get()
        .then((data: any) => {
          if (data.exists) {
            resolve(data.data())
          } else {
            resolve(false)
          }
        });
    })
  }

  getHorliSub(id){
    return this.afs.collection("horlies").doc(id).valueChanges();
  }

  registerLog() {
    var day = new Date()
    var end = new Date(day.getFullYear() + 1, day.getMonth(), day.getDate())
    this.afs.collection("buyingLogs").add({
      name: this.userInfo.name,
      job: this.userInfo.job,
      email: this.userInfo.email,
      phone: this.userInfo.phone,
      buyDate: day,
      buyDateISO: day.toISOString(),
      endDate: end,
      endDateISO: end.toISOString()
    })
  }

  getCollHorlies(id){
    return this.afs.collection("horlies").doc(id).valueChanges()
  }

}
