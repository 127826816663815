import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { MyPresentationComponent } from './pages/my-presentation/my-presentation.component';
import { MyHorliComponent } from './pages/my-horli/my-horli.component';
import { FormComponent } from './pages/form/form.component';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { StepFourComponent } from './components/step-four/step-four.component';
import { StepFiveComponent } from './components/step-five/step-five.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'presentation',
    component: MyPresentationComponent
  },
  {
    path: 'presentation/:user',
    component: MyPresentationComponent
  },
  {
    path: 'my-networking/:user',
    component: MyHorliComponent
  },
  {
    path: 'register',
    component: FormComponent
  },
  {
    path: ':user',
    component: ConfirmComponent
  },
  {
    path: 'form/:user',
    component: FormComponent
  },
  {
    path: ':user/confirm',
    component: ConfirmComponent
  },
  {
    path: 'page/not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
