import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BannerComponent } from './components/banner/banner.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { WhyHorliComponent } from './components/why-horli/why-horli.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { PriceInfoComponent } from './components/price-info/price-info.component';
import { FooterComponent } from './components/footer/footer.component';
import { MyPresentationComponent } from './pages/my-presentation/my-presentation.component';
import { MyHorliComponent } from './pages/my-horli/my-horli.component';
import { GeneralInfoComponent } from './components/general-info/general-info.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ExperiencesComponent } from './components/experiences/experiences.component';
import { AchievementsComponent } from './components/achievements/achievements.component';
import { FlipModule } from 'ngx-flip';
import { MyselfComponent } from './components/myself/myself.component';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { FormComponent } from './pages/form/form.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { StepOneComponent } from './components/step-one/step-one.component';
import { StepTwoComponent } from './components/step-two/step-two.component';
import { StepThreeComponent } from './components/step-three/step-three.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { StepFourComponent } from './components/step-four/step-four.component';
import { StepFiveComponent } from './components/step-five/step-five.component';
import { StepSixComponent } from './components/step-six/step-six.component';
import { NgxMaskModule } from 'ngx-mask';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgxMasonryModule } from 'ngx-masonry';
import { MasonryGalleryModule } from 'ngx-masonry-gallery';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContactHelpComponent } from './components/contact-help/contact-help.component';
import {MatTooltipModule} from '@angular/material/tooltip';

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    HomeComponent,
    HeaderComponent,
    WhyHorliComponent,
    HowItWorksComponent,
    PriceInfoComponent,
    FooterComponent,
    MyPresentationComponent,
    MyHorliComponent,
    GeneralInfoComponent,
    ExperiencesComponent,
    AchievementsComponent,
    MyselfComponent,
    CompanyInfoComponent,
    FormComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    StepFiveComponent,
    StepSixComponent,
    ConfirmComponent,
    NotFoundComponent,
    ContactHelpComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    FlipModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxDropzoneModule,
    NgxMaskModule.forRoot(),
    HttpClientModule,
    NgxMasonryModule,
    MasonryGalleryModule,
    ImageCropperModule,
    ModalModule.forRoot(),
    FontAwesomeModule,
    MatTooltipModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
