<form [formGroup]="professionalForm" style="overflow-y: auto;">
    <section *ngIf="showForm" [ngClass]=" activeClick ? 'activeClick' : 'good'">
        <div class="container">
            <h1>Información Profesional</h1>
            <div class="row payment">
                <div class="col-md-6 card-info">
                    <div class="form row">
                        <div class="form-group col-md-12"
                            [ngClass]="hasError('companyName', 'required') ? 'error' : 'good'">
                            <label for="companyName" class="col-form-label">NOMBRE DE MI EMPRESA </label>
                            <input type="text" class="form-control" formControlName="companyName">
                        </div>
                        <div class="form-group col-md-12"
                            [ngClass]="hasError('companyType', 'required') ? 'error' : 'good'">
                            <hr>
                            <label class="col-form-label pb-0">GIRO DE LA EMPRESA </label><br>
                            <textarea formControlName="companyType" class="form-control" id="companyType"
                                rows="3"></textarea>
                        </div>
                        <div class="form-group col-md-12"
                            [ngClass]="hasError('companyDescription', 'required') ? 'error' : 'good'">
                            <hr>
                            <label class="col-form-label pb-0">DESCRIPCIÓN COMPLETA DE EMPRESA </label><br>
                            <textarea formControlName="companyDescription" class="form-control" id="companyDescription"
                                rows="3"></textarea>
                        </div>
                        <div class="form-group col-md-12">
                            <hr>
                            <div class="row mb-3">
                                <div class="col-9"
                                    [ngClass]="!professionalForm.controls['oldJobs'].valid ? 'error' : 'good'">
                                    <label class="col-form-label pb-0">EXPERIENCIAS LABORALES PASADAS </label>
                                </div>
                                <div class="col-3 d-flex align-items-center">
                                    <button type="button" class="btn btn-danger tiny-button" (click)="addOldJob()">
                                        Añadir +
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div formArrayName="oldJobs">
                                    <div *ngFor="let item of getOldJobsArray.controls; let pointIndex = index"
                                        [formGroupName]="pointIndex" class="row">
                                        <div class="input-group mb-3" *ngIf="pointIndex > 2">
                                            <input type="text" class="form-control" formControlName="oldJob">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-danger btn-delete-custom" type="button"
                                                    id="contact_{{pointIndex}}"
                                                    (click)="removeParam(pointIndex, 'oldJobs')">Borrar</button>
                                            </div>
                                        </div>
                                        <div class="input-group mb-3" *ngIf="pointIndex >= 0 && pointIndex < 3"
                                            [ngClass]="emptyInputJob == pointIndex ?  'error1': 'good' ">
                                            <input type="text" class="form-control" formControlName="oldJob">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <label class="col-form-label pb-0">REFERENCIA IDEAL </label><br>
                            <textarea formControlName="companyReference" class="form-control" id="companyReference"
                                rows="3"></textarea> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 achievements">
                    <div class="form-goals row">
                        <div class="form-group col-md-12"
                            [ngClass]="hasError('companyLogo', 'required') ? 'error' : 'good'">
                            <label class="col-form-label">MI LOGO <small>*Imagen sin blancos.</small></label>
                            <!-- *ngIf="horliAux?.companyLogo == undefined || horliAux?.companyLogo == ''"  -->
                            <div class="custom-dropzone d-flex align-items-center" ngx-dropzone [accept]="'image/*'"
                                [multiple]=false (change)="onSelectLogo($event)" [maxFileSize]="8000000">
                                <ngx-dropzone-label>
                                    <div>
                                        <i class="fa fa-user-circle icon-user" aria-hidden="true"></i>
                                        <h4 style="font-size: 1rem; padding: 0 30px;">ADJUNTA EL LOGO DE TU EMPRESA AQUÍ
                                        </h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of logo"
                                    [file]="f" [removable]="true" (removed)="onRemoveLogo(f)"
                                    style="margin: auto !important;">
                                </ngx-dropzone-image-preview>
                            </div>
                            <!-- <div *ngIf="horliAux?.companyLogo != undefined && horliAux?.companyLogo != '' "  class="custom-dropzone d-flex align-items-center" >
                                <img [src]="horliAux?.companyLogo.url" class="img-logo" alt="">
                            </div> -->
                        </div>
                        <div class="form-group col-md-12"
                            [ngClass]="hasError('companyImage', 'required') ? 'error' : 'good'">
                            <label class="col-form-label">FOTO DE LA EMPRESA </label>
                            <!-- *ngIf="horliAux?.companyImage == undefined || horliAux?.companyImage == '' " -->
                            <div class="custom-dropzone  d-flex align-items-center" ngx-dropzone [accept]="'image/*'"
                                [multiple]=false (click)="file.click()" [maxFileSize]="8000000" [disableClick]="true"
                                (change)="onSelectImage($event)">
                                <ngx-dropzone-label>
                                    <div>
                                        <i class="fa fa-user-circle icon-user" aria-hidden="true"></i>
                                        <h4 style="font-size: 1rem; padding: 0 30px;">ADJUNTA LA FOTOGRAFÍA DE TU
                                            EMPRESA AQUÍ</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of image"
                                    [file]="f" [removable]="true" (removed)="onRemoveImage(f)"
                                    style="margin: auto !important;">
                                </ngx-dropzone-image-preview>
                            </div>
                            <!-- openModal(company) -->
                            <input type="file" class="file-input" accept="image/*" (change)="fileChangeEvent($event);"
                                data-max-size="8000000" #file />
                            <!-- <div *ngIf="horliAux?.companyImage != undefined && horliAux?.companyImage != '' " class="custom-dropzone d-flex align-items-center" >
                                <img [src]="horliAux?.companyImage.url" class="img-logo" alt="">
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <div>
                    <button (click)="goBack()" type="button" class="button-custom"><i class="fa fa-long-arrow-left"
                            aria-hidden="true"></i>
                        Volver</button>
                </div>
                <div>
                    <button type="button" (click)="saveChanges()" class="btn btn-link">Guardar cambios</button>
                    <button (click)="toggleForm('next')" class="button-custom" type="button">Siguiente <i
                            class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                    <!-- [disabled]="!contactForm.valid" -->
                </div>
            </div>
        </div>
        <ng-container *ngIf="loader">
            <div class="loader">
                <div class="spinner-border text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
    </section>
    <section *ngIf="!showForm" [ngClass]=" activeClickSecond ? 'activeClick' : 'good'">
        <div class="container">
            <h1>Información de Mi Empresa</h1>
            <div class="row payment">
                <div class="col-md-6 card-info">
                    <div class="form row">
                        <!-- <div class="form-group col-md-12">
                            <label for="whatIDo" class="col-form-label">LO QUE HAGO </label>
                            <div>
                                <textarea formControlName="whatIDo" type="text" class="form-control" id="whatIDo"
                                    rows="8"></textarea>
                            </div>
                        </div> -->

                        <div class="form-group col-md-12">
                            <div class="row mb-3">
                                <div class="col-9"
                                    [ngClass]="!professionalForm.controls['services'].valid ? 'error' : 'good'">
                                    <label class="col-form-label pb-0">SERVICIOS OFRECIDOS </label><i
                                        class="fa fa-question-circle"
                                        (click)="openTooltipModal(tooltipModal, 'services')"
                                        matTooltip="¿Necesitas ayuda?" matTooltipPosition="above"
                                        style="color: #5C70DD; margin-left: 1rem; cursor: pointer;"></i><br>
                                    <span class="custom-span">*Minimo 3</span>
                                </div>
                                <div class="col-3 d-flex align-items-center">
                                    <button type="button" class="btn btn-danger tiny-button" (click)="addService()">
                                        Añadir +
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div formArrayName="services">
                                    <div *ngFor="let item of getServicesArray.controls; let pointIndex = index"
                                        [formGroupName]="pointIndex" class="row">
                                        <div class="input-group mb-3" *ngIf="pointIndex > 2">
                                            <input type="text" class="form-control" formControlName="service">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-danger btn-delete-custom" type="button"
                                                    id="contact_{{pointIndex}}"
                                                    (click)="removeParam(pointIndex, 'services')">Borrar</button>
                                            </div>
                                        </div>
                                        <div class="input-group mb-3" *ngIf="pointIndex >= 0 && pointIndex < 3"
                                            [ngClass]="emptyInputService[pointIndex] ?  'error'+pointIndex : 'good' ">
                                            <input type="text" class="form-control" formControlName="service"
                                                (keypress)="cambioError(pointIndex,'services')">
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <hr>
                            <div class="row mb-3">
                                <div class="col-9"
                                    [ngClass]="!professionalForm.controls['companyReference'].valid ? 'error' : 'good'">
                                    <label class="col-form-label pb-0">REFERENCIAS IDEALES </label><i
                                        class="fa fa-question-circle"
                                        (click)="openTooltipModal(tooltipModal, 'references')"
                                        matTooltip="¿Necesitas ayuda?" matTooltipPosition="above"
                                        style="color: #5C70DD; margin-left: 1rem; cursor: pointer;"></i><br>
                                    <span class="custom-span">*Minimo 3</span>
                                </div>
                                <div class="col-3 d-flex align-items-center">
                                    <button type="button" class="btn btn-danger tiny-button" (click)="addReferences()">
                                        Añadir +
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div formArrayName="companyReference">
                                    <div *ngFor="let item of getReferencesArray.controls; let pointIndex = index"
                                        [formGroupName]="pointIndex" class="row">
                                        <div class="input-group mb-3" *ngIf="pointIndex > 2">
                                            <input type="text" class="form-control" formControlName="reference">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-danger btn-delete-custom" type="button"
                                                    id="reference_{{pointIndex}}"
                                                    (click)="removeParam(pointIndex, 'companyReference')">Borrar</button>
                                            </div>
                                        </div>
                                        <div class="input-group mb-3" *ngIf="pointIndex >= 0 && pointIndex < 3"
                                            [ngClass]="emptyInputReference[pointIndex] ?  'error'+pointIndex : 'good' ">
                                            <input type="text" class="form-control" formControlName="reference"
                                                (keypress)="cambioError(pointIndex,'references')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 ">
                    <div class="form-goals row">
                        <div class="form-group col-md-12">
                            <div class="row mb-3">
                                <div class="col-9"
                                    [ngClass]="!professionalForm.controls['contacts'].valid ? 'error' : 'good'">
                                    <label class="col-form-label pb-0">ESFERA DE CONTACTOS </label><i
                                        class="fa fa-question-circle"
                                        (click)="openTooltipModal(tooltipModal, 'contacts')"
                                        matTooltip="¿Necesitas ayuda?" matTooltipPosition="above"
                                        style="color: #5C70DD; margin-left: 1rem; cursor: pointer;"></i><br>
                                    <span class="custom-span">*Minimo 3</span>
                                </div>
                                <div class="col-3 d-flex align-items-center">
                                    <button type="button" class="btn btn-danger tiny-button" (click)="addContact()">
                                        Añadir +
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div formArrayName="contacts">
                                    <div *ngFor="let item of getContacsArray.controls; let pointIndex = index"
                                        [formGroupName]="pointIndex" class="row">
                                        <div class="input-group mb-3" *ngIf="pointIndex > 2">
                                            <input type="text" class="form-control" formControlName="contact">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-danger btn-delete-custom" type="button"
                                                    id="contact_{{pointIndex}}"
                                                    (click)="removeParam(pointIndex, 'contacts')">Borrar</button>
                                            </div>
                                        </div>
                                        <div class="input-group mb-3" *ngIf="pointIndex >= 0 && pointIndex < 3"
                                            [ngClass]="emptyInputContact[pointIndex] ?  'error'+pointIndex : 'good' ">
                                            <input type="text" class="form-control" formControlName="contact"
                                                (keypress)="cambioError(pointIndex,'contacts')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <hr>
                            <div class="row mb-3">
                                <div class="col-9"
                                    [ngClass]="!professionalForm.controls['clients'].valid ? 'error' : 'good'">
                                    <label class="col-form-label pb-0">ÚLTIMOS CLIENTES </label><i
                                        class="fa fa-question-circle"
                                        (click)="openTooltipModal(tooltipModal, 'clients')"
                                        matTooltip="¿Necesitas ayuda?" matTooltipPosition="above"
                                        style="color: #5C70DD; margin-left: 1rem; cursor: pointer;"></i><br>
                                    <span class="custom-span">*Minimo 3</span>
                                </div>
                                <div class="col-3 d-flex align-items-center">
                                    <button type="button" class="btn btn-danger tiny-button" (click)="addClient()">
                                        Añadir +
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div formArrayName="clients">
                                    <div *ngFor="let item of getClientsArray.controls; let pointIndex = index"
                                        [formGroupName]="pointIndex" class="row">
                                        <div class="input-group mb-3" *ngIf="pointIndex > 2"
                                            [ngClass]="!this.getClientsArray.at(pointIndex).valid ? 'error' : 'good'">
                                            <input type="text" class="form-control" formControlName="client">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-danger btn-delete-custom" type="button"
                                                    id="client_{{pointIndex}}"
                                                    (click)="removeParam(pointIndex, 'clients')">Borrar</button>
                                            </div>
                                        </div>
                                        <div class="input-group mb-3" *ngIf="pointIndex >= 0 && pointIndex < 3"
                                            [ngClass]="emptyInputClient[pointIndex] ?  'error'+pointIndex : 'good' ">
                                            <input type="text" class="form-control" formControlName="client"
                                                (keypress)="cambioError(pointIndex,'clients')">
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <div>
                    <button (click)="toggleForm('back')" type="button" class="button-custom"><i
                            class="fa fa-long-arrow-left" aria-hidden="true"></i>
                        Volver </button>
                </div>
                <div>
                    <button type="button" (click)="saveChanges()" class="btn btn-link">Guardar cambios</button>
                    <button (click)="onSubmit()" type="button" class="button-custom">Siguiente <i
                            class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                    <!-- [disabled]="!professionalForm.valid" -->
                </div>
            </div>
        </div>
        <ng-container *ngIf="loader">
            <div class="loader">
                <div class="spinner-border text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
    </section>
</form>

<ng-template #company>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Ajustar imagen</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="20 / 23"
            format="jpg" [hideResizeSquares]="true" (imageCropped)="imageCropped($event)" [onlyScaleDown]="true"
            [transform]="transform"></image-cropper>
        <input type="range" min="0.5" max="2" step="0.1" value="1" class="range-zoom"
            (input)="valueChanged($event.target.value)">
        <div>
            <img [src]="croppedImage" class="img-user" alt="">
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="modalRef.hide()" class="btn btn-success">Aceptar</button>
        <button (click)="closeModal(1);" class="btn btn-danger">Cancelar</button>
    </div>
</ng-template>

<ng-template #tooltipModal>
    <ng-container [ngSwitch]="tooltip">
        <ng-container *ngSwitchCase="'services'">
            <div class="modal-body tooltip-modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-12 col-lg-7">
                            <img src="../../../assets/img/tooltips/desktop/Servicios.png"
                                class="tooltip-img desktop-img" alt="">
                            <img src="../../../assets/img/tooltips/movil/Servicios.png" class="tooltip-img mobile-img"
                                alt="">
                        </div>
                        <div class="col-md-12 col-lg-5 tooltip-info">
                            <!-- <span aria-hidden="true" class="pull-right btn-close-modal" (click)="modalRef.hide()">&times;</span> -->
                            <img src="../../../assets/img/cancel.svg" class="pull-right btn-close-modal"
                                (click)="modalRef.hide()" alt="">
                            <p class="info-title">Servicios ofrecidos</p>
                            <p>Solo podrán darte referencias exitosas si verdaderamente conocen la esencia y función de
                                los servicios y productos ofrecidos por ti, así podrán identificar oportunidades de
                                negocio para ti y el objetivo del networking se habrá alcanzado. </p>
                            <p>Es importante ser claro y conciso para facilitar el entendimiento a los demás networkers.
                            </p>
                            <p class="example-text"><span>Ejemplo actual basado en:</span> "Obi Wan Kenobi, Maestro
                                Jedi"</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button (click)="modalRef.hide()" class="btn btn-success">Entendido</button>
            </div> -->
        </ng-container>

        <ng-container *ngSwitchCase="'references'">
            <div class="modal-body tooltip-modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-12 col-lg-8">
                            <img src="../../../assets/img/tooltips/desktop/Referencia Ideal.png"
                                class="tooltip-img desktop-img" alt="">
                            <img src="../../../assets/img/tooltips/movil/Referencia Ideal.png"
                                class="tooltip-img mobile-img" alt="">
                        </div>
                        <div class="col-md-12 col-lg-4 tooltip-info">
                            <!-- <span aria-hidden="true" class="pull-right btn-close-modal" (click)="modalRef.hide()">&times;</span> -->
                            <img src="../../../assets/img/cancel.svg" class="pull-right btn-close-modal"
                                (click)="modalRef.hide()" alt="">
                            <p class="info-title">Referencias Ideales</p>
                            <p>Ayuda a que los demás te refieran brindando ejemplos claros de los perfiles ideales para
                                clientes potenciales, esto para garantizar poder proveer oportunidades de negocios para
                                todos. </p>
                            <p> Recuerda que ser específico, facilita a las demás personas el pensar en ese contacto que
                                quieres que te refiera.</p>
                            <p class="example-text"><span>Ejemplo actual basado en:</span> "Obi Wan Kenobi, Maestro
                                Jedi"</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button (click)="modalRef.hide()" class="btn btn-success">Entendido</button>
            </div> -->
        </ng-container>

        <ng-container *ngSwitchCase="'contacts'">
            <div class="modal-body tooltip-modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-12 col-lg-8">
                            <img src="../../../assets/img/tooltips/desktop/Esfera de Contactos.png"
                                class="tooltip-img desktop-img" alt="">
                            <img src="../../../assets/img/tooltips/movil/Esfera de contactos.png"
                                class="tooltip-img mobile-img" alt="">
                        </div>
                        <div class="col-md-12 col-lg-4 tooltip-info">
                            <!-- <span aria-hidden="true" class="pull-right btn-close-modal" (click)="modalRef.hide()">&times;</span> -->
                            <img src="../../../assets/img/cancel.svg" class="pull-right btn-close-modal"
                                (click)="modalRef.hide()" alt="">
                            <p class="info-title">Esfera de contactos</p>
                            <p>Es importante que los demás networkers conozcan tu esfera de contactos, para poderte
                                referir con el perfil de persona que pueda ayudarte a generar más negocio.</p>
                            <p>Ej. Si eres un arquitecto, pudieran ser constructoras, peritos, inmobiliarias, notarías,
                                etc.</p>
                            <p class="example-text"><span>Ejemplo actual basado en:</span> "Obi Wan Kenobi, Maestro
                                Jedi"</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button (click)="modalRef.hide()" class="btn btn-success">Entendido</button>
            </div> -->
        </ng-container>

        <ng-container *ngSwitchCase="'clients'">
            <div class="modal-body tooltip-modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-12 col-lg-8">
                            <img src="../../../assets/img/tooltips/desktop/Ultimos Clientes.png"
                                class="tooltip-img desktop-img" alt="">
                            <img src="../../../assets/img/tooltips/movil/Ultimos clientes.png"
                                class="tooltip-img mobile-img" alt="">
                        </div>
                        <div class="col-md-12 col-lg-4 tooltip-info">
                            <!-- <span aria-hidden="true" class="pull-right btn-close-modal" (click)="modalRef.hide()">&times;</span> -->
                            <img src="../../../assets/img/cancel.svg" class="pull-right btn-close-modal"
                                (click)="modalRef.hide()" alt="">
                            <p class="info-title">Últimos clientes</p>
                            <p>Para fomentar la confianza y fortalecer la reputación de tu persona y de tu empresa, lo
                                mejor que puedes hacer es dar ejemplos de casos de éxito, como tus últimos clientes.</p>
                            <p class="example-text"><span>Ejemplo actual basado en:</span> "Obi Wan Kenobi, Maestro
                                Jedi"</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button (click)="modalRef.hide()" class="btn btn-success">Entendido</button>
            </div> -->
        </ng-container>
    </ng-container>
</ng-template>