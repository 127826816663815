<footer>
    <div class="content">
        <div class="logo">
            <img src="assets/img/dapper.png" class="img-fluid align-middle" alt="">
        </div>
        <div class="site">
            <div>
                <a href="https://dappertechnologies.com" target="_blank">DAPPERTECHNOLOGIES.COM</a>
                <hr>
            </div>
        </div>
        <div class="social">
            <ul>
                <li>
                    <a href="">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                </li>
                <li>
                    <a href="">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</footer>