<section id="how-works">
    <h1 class="text-center custom-header"> Cómo funciona </h1>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 text-center mb-5">
                <img src="assets/img/1.jpg" class="custom-image" alt="formulario">
                <div class="circulo mx-auto  mt-3">
                    <p>1</p>
                </div>
                <div class="mx-5 mt-3 text">
                    <h5 class="text-center mt-3">Inicia tu registro</h5>
                    <p class="text-center">Proporciona toda la información necesaria para crear tu hoja de Networking.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 text-center mb-5" style="position: relative;">
                <img src="assets/img/3.jpg" class="custom-image" alt="contenido">
                <hr class="left">
                <div class="circulo mx-auto mt-3">
                    <p>2</p>
                </div>
                <hr class="right">
                <div class="mx-5 mt-3 text">
                    <h5 class="text-center mt-3"> Realiza tu pago</h5>
                    <p class="text-center">Selecciona un método de pago, confirma y comienza a crear tu hoja de networking.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0 text-center mb-5">
                <img src="assets/img/2.jpg" class="custom-image" alt="pago">
                <div class="circulo mx-auto mt-3">
                    <p>3</p>
                </div>
                <hr class="extra">
                <div class="mx-5 mt-3 text">
                    <h5 class="text-center  mt-3"> Agrega tu contenido </h5>
                    <p class="text-center">Llena el formulario con tu información profesional, personal y de tu empresa.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0 text-center mb-5">
                <img src="assets/img/5.jpg" class="custom-image" alt="pago">
                <div class="circulo mx-auto mt-3">
                    <p>4</p>
                </div>
                <div class="mx-5 mt-3 text">
                    <h5 class="text-center  mt-3"> ¡Empieza a compartir! </h5>
                    <p class="text-center">Manda tu link antes de tus sesiones. </p>
                    <p class="text-center">¡Feliz networking!</p>
                </div>
            </div>

        </div>
        <div class="col-12 d-flex justify-content-center">
            <button class="btn custom-button  btn-lg" [routerLink]="['/register']">Comencemos <i class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
        </div>
    </div>
</section>