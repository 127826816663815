<div class="theme-main-menu theme-menu-three">
    <div class="container cont">
        <nav class="nav-menu">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a href="javascript:void(0)" class="nav-link" (click)="scrollTo('how-works')">¿Cómo funciona?</a>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0)" class="nav-link" (click)="scrollTo('benefits')">Beneficios</a>
                </li>
            </ul>
        </nav>
        <div class="logo">
            <a [routerLink]="['']"><img src="assets/img/networking.png" alt="" ></a>
        </div>
    </div>
</div>