<section>
    <div class="present container" data-aos="fade-right">
        <div class="person">
            <h1>{{ horli?.companyName }}</h1>
            <p>{{ horli?.companyType }}</p>
        </div>
    </div>
    <div class="info container">
        <div class="person" data-aos="zoom-in">
            <img [src]="horli?.companyLogo.url" class="img-fluid company" alt="">
        </div>
        <div class="details col-md-12" data-aos="zoom-in-left">
            <!-- <div class="col-12">
                <h5><strong>Descripción Completa</strong></h5>
                <p>
                    {{ horli?.companyDescription }}
                </p>
            </div> -->
            <div class="col-12 first-row">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="list">
                            <h2>Servicios</h2>
                            <ul>
                                <div *ngFor="let item of horli?.services;">
                                    <li *ngIf='item.service != "" '>
                                        {{ item.service }}
                                    </li>
                                </div>
                            </ul>
                        </div>                    
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="list">
                            <h2>Esfera de Contactos</h2>
                            <ul>
                                <div *ngFor="let item of horli?.contacts;">
                                    <li *ngIf='item.contact != "" '>
                                        {{ item.contact }}
                                    </li>
                                </div>
                            </ul>
                        </div>  
                    </div>                
                </div>
            </div>
            <div class="col-12 second-row" style="position: relative;">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="list">
                            <h2>Referencia Ideal</h2>
                            <!-- {{ horli?.companyReference }} -->
                            <ul>
                                <div *ngFor="let item of horli?.companyReference;">
                                    <li *ngIf='item.reference != "" '>
                                        {{ item.reference }}
                                    </li>
                                </div>
                            </ul>
                        </div>                    
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="list">
                            <h2>Últimos Clientes</h2>
                            <ul>
                                <div *ngFor="let item of horli?.clients;">
                                    <li *ngIf='item.client != "" '>
                                        {{ item.client }}
                                    </li>
                                </div>
                                
                            </ul>
                        </div>  
                    </div>                
                </div>
            </div>
        </div>
    </div>    
    <img src="assets/img/Detalle Empresa.png" class="detail-view" alt="">
</section>