import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from 'src/app/services/register/register.service';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit {

  @Output() next = new EventEmitter<string>();
  contactForm: FormGroup

  constructor(
    public fb: FormBuilder,
    public registerService: RegisterService
  ) {

    this.contactForm = this.fb.group({
      name: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      job: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,63}')
      ])],
      phone: ['', Validators.compose([
        Validators.required,
        Validators.minLength(10)
      ])]
    })

  }

  ngOnInit(): void {
  }

  hasError = (controlName: string, errorName: string) => {
    return this.contactForm.controls[controlName].hasError(errorName);
  }

  goForward() {
    this.next.emit("one")
    var form = this.contactForm.value
    this.contactForm.patchValue({
      name: form.first_name + " " + form.last_name
    })
    form = this.contactForm.value
    this.registerService.setUserInfo(form)
  }

  keyPress(e: any, c) {
    if(parseInt(e.key) < 9 || e.code == "Space" && this.contactForm.controls[c].value.length == 0){
      e.preventDefault()
    }
  }
  
}
