import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConektaService } from 'src/app/services/conekta/conekta.service';
import { MailService } from 'src/app/services/mail/mail.service';
import { RegisterService } from 'src/app/services/register/register.service';
import Swal from 'sweetalert2';
declare var Conekta;

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit {

  @Output() next = new EventEmitter<string>();
  @Output() back = new EventEmitter<string>();
  payForm: FormGroup
  loader: boolean = false;
  step: string = "one";
  user
  isReActivation = false
  idUser

  cardMask = '0000-0000-0000-0000';
  cardCVVMask = '000';
  cardType = '';

  constructor(
    public fb: FormBuilder,
    public conektaService: ConektaService,
    public registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute,
    private mailService: MailService
  ) {

    this.payForm = this.fb.group({
      name: ['', Validators.required],
      card: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required],
      cvv: ['', Validators.required]
    })

  }

  ngOnInit(): void {
    // prod
    Conekta.setPublicKey("key_WhrGseqbxAUsMsWkbTvHPfw");
    //pruebas
    // Conekta.setPublicKey("key_PwqSpXgGm1wqjvSLD6y2txg");
    Conekta.setLanguage("es");
    setTimeout(() => {
      this.isReActivation = this.registerService.getReActive
    }, 1000);
    this.idUser = this.route.snapshot.paramMap.get("user");
  }

  getBrand(event) {
    let cardType = Conekta.card.getBrand(event);
    if (event.length >= 4) {
      switch (cardType) {
        case 'visa':
          this.cardMask = '0000-0000-0000-0000';
          this.cardType = cardType;
          this.cardCVVMask = '000';

          break;
        case 'amex':
          this.cardMask = '0000-000000-00000';
          this.cardType = cardType
          this.cardCVVMask = '0000';

          break;
        case 'mastercard':
          this.cardMask = '0000-0000-0000-0000';
          this.cardType = cardType;
          this.cardCVVMask = '000';
          break;

        default:
          break;
      }
    } else {
      this.cardMask = '0000-0000-0000-0000';
      this.cardType = '';
      this.cardCVVMask = '000';
    }


  }

  doPay() {
    this.loader = true
    var card = this.payForm.value
    const tokenParams = {
      card: {
        number: card.card,
        name: card.name,
        exp_year: "20" + card.year,
        exp_month: card.month,
        cvc: card.cvv
      }
    }
    Conekta.Token.create(tokenParams, this.conektaSuccessResponseHandler, this.conektaErrorResponseHandler);
  }

  conektaSuccessResponseHandler = (token) => {
    var card = this.payForm.value
    this.user = this.registerService.getUserInfo
    let customer_info = {
      name: card.name,
      phone: this.user.phone || "0000000000",
      email: this.user.email,
    }

    let line_items = [{
      name: "Formato Networking",
      unit_price: 39900,
      quantity: 1,
    }];
    let test_token_visa = "tok_test_visa_4242";
    let test_token_mastercard = "tok_test_mastercard_4444";
    // targjeta amex para pruebas: 3782 8224 6310 005
    let test_token_amex = "tok_test_amex_0005";
    // token.id
    this.conektaService.createOrder(customer_info, line_items, token.id).subscribe({
      next: data => {
        this.registerService.registerLog()
        if (this.registerService.getReActive) {
          this.registerService.reActiveDoc(this.idUser).then(() => {
            this.step = "two"
            this.loader = false
          })
        } else {
          this.registerService.addNewUser().then(res => {
            this.mailService.sendConfirmMail(res).subscribe({
              next: data => {
              }, error: error => {
                console.log('error', error)
              }
            })
            this.step = "two"
            this.loader = false
          })
        }
      },
      error: error => {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Ah ocurrido un error con la tarjeta",
          confirmButtonText: 'Entendido',
          customClass: {
            container: "modal-error"
          }
        }).then(() => {
          this.loader = false
        })
      }
    })
  }

  conektaErrorResponseHandler = (response) => {
    console.log(response)
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: response.message_to_purchaser,
      confirmButtonText: 'Entendido',
      customClass: {
        container: "modal-error"
      }
    }).then(() => {
      this.loader = false
    })
  }

  goBack() {
    this.back.emit("two")
  }

  goToThree() {
    this.loader = true
    this.user = this.registerService.getUserInfo
    this.step = "three";
    this.loader = false
  }

  goToConfirm() {
    this.user = this.registerService.getUserInfo
    this.loader = true
    var route = '/' + this.user.linkId + '/confirm'
    if (this.user.linkId == undefined) {
      route = '/' + this.idUser + '/confirm'
    }
    this.router.navigateByUrl(route)
    this.loader = false
  }

}
