<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12 title">
                <img src="assets/img/trophy.svg" alt="">
                <h1>Logros</h1>
            </div>
            <div class="col-lg-4 col-sm-6 item" data-aos="zoom-in-right">
                <div class="flip-container">
                    <div class="front">
                        <img [src]="horli?.achievements[0].image.url" alt="">
                    </div>
                    <div class="back">
                        <h1>{{ horli?.achievements[0].achievement }}</h1>
                        <p>{{ horli?.achievements[0].description }}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 item" data-aos="zoom-in">
                <div class="flip-container">
                    <div class="front">
                        <img [src]="horli?.achievements[1].image.url" alt="">
                    </div>
                    <div class="back">
                        <h1>{{ horli?.achievements[1].achievement }}</h1>
                        <p>{{ horli?.achievements[1].description }}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 item" data-aos="zoom-in-left">
                <div class="flip-container">
                    <div class="front">
                        <img [src]="horli?.achievements[2].image.url" alt="">
                    </div>
                    <div class="back">
                        <h1>{{ horli?.achievements[2].achievement }}</h1>
                        <p>{{ horli?.achievements[2].description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>