<form [formGroup]="achievementsForm" style="overflow-y: auto;">
    <section *ngIf="showForm" [ngClass]=" activeClick ? 'activeClick' : 'good'">
        <div class="container">
            <h1>Momentos Destacados</h1>
            <div class="form col-md-12">
                <div class="form-group achievements" formArrayName="achievements">
                    <label class="col-form-label">LOGROS </label> <i class="fa fa-question-circle"
                        (click)="openTooltipModal(tooltipModal, 'achievements')" matTooltip="¿Necesitas ayuda?"
                        matTooltipPosition="above" style="color: #5C70DD; margin-left: 1rem; cursor: pointer;"></i>
                    <div [formGroupName]="0" class="row mb-5">
                        <!-- *ngIf="horliAux?.achievements == undefined || horliAux?.achievements[0].image == ''" -->
                        <div class="form-group col-md-4"
                            [ngClass]="!this.getAchievementsArray.at(0).get('image').valid ? 'error' : 'good'">
                            <div class="custom-dropzone d-flex align-items-center" ngx-dropzone [accept]="'image/*'"
                                [multiple]=false [disableClick]="true" (click)="file1.click()">
                                <ngx-dropzone-label>
                                    <div>
                                        <i class="fa fa-image icon" aria-hidden="true"></i>
                                        <h4 style="font-size: .9rem; padding: 0 30px;">ADJUNTA UNA FOTO DE TU LOGRO</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngIf="achievement1"
                                    [file]="achievement1" [removable]="true" (removed)="onRemoveAchievement(1)"
                                    style="margin: auto !important;">
                                </ngx-dropzone-image-preview>
                            </div>
                            <input type="file" class="file-input" accept="image/*"
                                (change)="fileChangeEvent($event,1,logros);" #file1 />
                        </div>
                        <!-- <div *ngIf="horliAux?.achievements != undefined && horliAux?.achievements[0].image != ''" class="form-group col-md-4">
                            <div class="custom-dropzone d-flex align-items-center">
                                <img [src]="horliAux?.achievements[0].image.url" class="img-achievement" alt="">
                            </div>
                        </div> -->
                        <div class="form-group col-md-8">
                            <span class="span-custom">Máx {{ countAchievementTitle1 }}</span>
                            <input type="text" class="form-control" max="40" formControlName="achievement"
                                [ngClass]="!this.getAchievementsArray.at(0).get('achievement').valid ? 'error' : 'good'"
                                (keyup)="onKey($event, 1, 'input', 50)" placeholder="¿QUÉ LOGRASTE?">
                            <span class="span-custom">Máx {{ countAchievement1 }} </span>
                            <textarea formControlName="description" maxlength="360" type="text"
                                (keyup)="onKey($event, 1, 'area', 360)" class="form-control" id="description" rows="3"
                                placeholder="CUÉNTANOS MÁS DE LO QUE LOGRASTE"
                                [ngClass]="!this.getAchievementsArray.at(0).get('description').valid ? 'error' : 'good'"></textarea>
                        </div>
                    </div>

                    <div [formGroupName]="1" class="row mb-5">
                        <!-- *ngIf="horliAux?.achievements == undefined || horliAux?.achievements[1].image == ''" -->
                        <div class="form-group col-md-4"
                            [ngClass]="!this.getAchievementsArray.at(1).get('image').valid ? 'error' : 'good'">
                            <div class="custom-dropzone d-flex align-items-center" ngx-dropzone [accept]="'image/*'"
                                [multiple]=false [disableClick]="true" (click)="file2.click()">
                                <ngx-dropzone-label>
                                    <div>
                                        <i class="fa fa-image icon" aria-hidden="true"></i>
                                        <h4 style="font-size: .9rem; padding: 0 30px;">ADJUNTA UNA FOTO DE TU LOGRO</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                    *ngIf="achievement2 != undefined" [file]="achievement2" [removable]="true"
                                    (removed)="onRemoveAchievement(2)" style="margin: auto !important;">
                                </ngx-dropzone-image-preview>
                            </div>
                            <input type="file" class="file-input" accept="image/*"
                                (change)="fileChangeEvent($event,2,logros);" #file2 />
                        </div>
                        <!-- <div *ngIf="horliAux?.achievements != undefined  && horliAux?.achievements[1].image != ''" class="form-group col-md-4">
                            <div class="custom-dropzone d-flex align-items-center">
                                <img [src]="horliAux?.achievements[1].image.url" class="img-achievement" alt="">
                            </div>
                        </div> -->
                        <div class="form-group col-md-8">
                            <span class="span-custom">Máx {{ countAchievementTitle2 }}</span>
                            <input
                                [ngClass]="!this.getAchievementsArray.at(1).get('achievement').valid ? 'error' : 'good'"
                                type="text" class="form-control" formControlName="achievement"
                                (keyup)="onKey($event, 2, 'input', 50)" placeholder="¿QUÉ LOGRASTE?">
                            <span class="span-custom">Máx {{ countAchievement2 }}</span>
                            <textarea formControlName="description" type="text" class="form-control" maxlength="360"
                                (keyup)="onKey($event, 2, 'area', 360)" id="description" rows="3"
                                placeholder="CUÉNTANOS MÁS DE LO QUE LOGRASTE"
                                [ngClass]="!this.getAchievementsArray.at(1).get('description').valid ? 'error' : 'good'"></textarea>
                        </div>
                    </div>

                    <div [formGroupName]="2" class="row mb-2">
                        <!-- *ngIf="horliAux?.achievements == undefined || horliAux?.achievements[2].image == ''" -->
                        <div class="form-group col-md-4"
                            [ngClass]="!this.getAchievementsArray.at(2).get('image').valid ? 'error' : 'good'">
                            <div class="custom-dropzone d-flex align-items-center" ngx-dropzone [accept]="'image/*'"
                                [multiple]=false [disableClick]="true" (click)="file3.click()">
                                <ngx-dropzone-label>
                                    <div>
                                        <i class="fa fa-image icon" aria-hidden="true"></i>
                                        <h4 style="font-size: .9rem; padding: 0 30px;">ADJUNTA UNA FOTO DE TU LOGRO</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                    *ngIf="achievement3 != undefined" [file]="achievement3" [removable]="true"
                                    (removed)="onRemoveAchievement(3)" style="margin: auto !important;">
                                </ngx-dropzone-image-preview>
                            </div>
                            <input type="file" class="file-input" accept="image/*"
                                (change)="fileChangeEvent($event,3,logros);" #file3 />
                        </div>
                        <!-- <div *ngIf="horliAux?.achievements != undefined && horliAux?.achievements[2].image != ''" class="form-group col-md-4">
                            <div class="custom-dropzone d-flex align-items-center">
                                <img [src]="horliAux?.achievements[2].image.url" class="img-achievement" alt="">
                            </div>
                        </div> -->
                        <div class="form-group col-md-8">
                            <span class="span-custom">Máx {{ countAchievementTitle3 }}</span>
                            <input
                                [ngClass]="!this.getAchievementsArray.at(2).get('achievement').valid ? 'error' : 'good'"
                                type="text" class="form-control" formControlName="achievement"
                                (keyup)="onKey($event, 3, 'input', 50)" placeholder="¿QUÉ LOGRASTE?">
                            <span class="span-custom">Máx {{ countAchievement3 }}</span>

                            <textarea formControlName="description" type="text" class="form-control" maxlength="360"
                                (keyup)="onKey($event, 3, 'area', 360)" id="description" rows="3"
                                placeholder="CUÉNTANOS MÁS DE LO QUE LOGRASTE"
                                [ngClass]="!this.getAchievementsArray.at(2).get('description').valid ? 'error' : 'good'"></textarea>

                        </div>
                    </div>
                </div>
                <!-- <pre> {{ files | json }} </pre> -->
            </div>
            <div class="buttons">
                <div>
                    <button (click)="goBack()" type="button" class="button-custom"><i class="fa fa-long-arrow-left"
                            aria-hidden="true"></i>
                        Volver</button>
                </div>
                <div>
                    <button type="button" (click)="saveChanges()" class="btn btn-link">Guardar cambios</button>
                    <button (click)="toogleForm()" type="button" class="button-custom">Siguiente <i
                            class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
        <ng-container *ngIf="loader">
            <div class="loader">
                <div class="spinner-border text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
    </section>
    <section *ngIf="!showForm" [ngClass]=" activeClickSecond ? 'activeClick' : 'good'">
        <div class="container">
            <h1>Momentos Destacados</h1>
            <div class="form col-md-12">
                <div class="form-group achievements" formArrayName="experiences">
                    <label class="col-form-label">EXPERIENCIAS ENRIQUECEDORAS </label> <i class="fa fa-question-circle"
                        (click)="openTooltipModal(tooltipModal, 'experiences')" matTooltip="¿Necesitas ayuda?"
                        matTooltipPosition="above" style="color: #5C70DD; margin-left: 1rem; cursor: pointer;"></i>
                    <div [formGroupName]="0" class="row mb-5">
                        <!-- *ngIf="horliAux?.experiences == undefined || horliAux?.experiences[0].image == ''" -->
                        <div class="form-group col-md-4"
                            [ngClass]="!this.getExperiencesArray.at(0).get('image').valid ? 'error' : 'good'">
                            <div class="custom-dropzone d-flex align-items-center" ngx-dropzone [accept]="'image/*'"
                                [multiple]=false [disableClick]="true" (click)="file4.click()">
                                <ngx-dropzone-label>
                                    <div>
                                        <i class="fa fa-image icon" aria-hidden="true"></i>
                                        <h4 style="font-size: .9rem; padding: 0 30px;">ADJUNTA UNA FOTO DE TU
                                            EXPERIENCIA</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                    *ngIf="experience1 != undefined" [file]="experience1" [removable]="true"
                                    (removed)="onRemoveExperience(1)" style="margin: auto !important;">
                                </ngx-dropzone-image-preview>
                            </div>
                            <input type="file" class="file-input" accept="image/*"
                                (change)="fileChangeEvent($event,1,experiences);" #file4 />
                        </div>
                        <!-- <div *ngIf="horliAux?.experiences != undefined && horliAux?.experiences[0].image != '' " class="form-group col-md-4">
                            <div class="custom-dropzone d-flex align-items-center">
                                <img [src]="horliAux?.experiences[0].image.url" class="img-achievement" alt="">
                            </div>
                        </div> -->
                        <div class="form-group col-md-8">
                            <input
                                [ngClass]="!this.getExperiencesArray.at(0).get('experience').valid ? 'error' : 'good'"
                                type="text" class="form-control my-2" placeholder="¿CUÁL FUE TU EXPERENCIA?"
                                formControlName="experience">
                            <textarea formControlName="description"
                                [ngClass]="!this.getExperiencesArray.at(0).get('description').valid ? 'error' : 'good'"
                                type="text" class="form-control" id="description" rows="4"
                                placeholder="CUÉNTANOS MÁS DE TU EXPERIENCIA"></textarea>
                        </div>
                    </div>
                    <div [formGroupName]="1" class="row mb-5">
                        <!-- *ngIf="horliAux?.experiences == undefined || horliAux?.experiences[1].image == ''" -->
                        <div class="form-group col-md-4"
                            [ngClass]="!this.getExperiencesArray.at(1).get('image').valid ? 'error' : 'good'">
                            <div class="custom-dropzone d-flex align-items-center" ngx-dropzone [accept]="'image/*'"
                                [multiple]=false [disableClick]="true" (click)="file5.click()">
                                <ngx-dropzone-label>
                                    <div>
                                        <i class="fa fa-image icon" aria-hidden="true"></i>
                                        <h4 style="font-size: .9rem; padding: 0 30px;">ADJUNTA UNA FOTO DE TU
                                            EXPERIENCIA</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                    *ngIf="experience2 != undefined" [file]="experience2" [removable]="true"
                                    (removed)="onRemoveExperience(2)" style="margin: auto !important;">
                                </ngx-dropzone-image-preview>
                            </div>
                            <input type="file" class="file-input" accept="image/*"
                                (change)="fileChangeEvent($event,2,experiences);" #file5 />
                        </div>
                        <!-- <div *ngIf="horliAux?.experiences != undefined  && horliAux?.experiences[1].image != '' " class="form-group col-md-4">
                            <div class="custom-dropzone d-flex align-items-center">
                                <img [src]="horliAux?.experiences[1].image.url" class="img-achievement" alt="">
                            </div>
                        </div> -->
                        <div class="form-group col-md-8">
                            <input
                                [ngClass]="!this.getExperiencesArray.at(1).get('experience').valid ? 'error' : 'good'"
                                type="text" class="form-control my-2" placeholder="¿CUÁL FUE TU EXPERENCIA?"
                                formControlName="experience">
                            <textarea
                                [ngClass]="!this.getExperiencesArray.at(1).get('description').valid ? 'error' : 'good'"
                                formControlName="description" type="text" class="form-control" id="description" rows="4"
                                placeholder="CUÉNTANOS MÁS DE TU EXPERIENCIA"></textarea>
                        </div>
                    </div>
                    <div [formGroupName]="2" class="row mb-2">
                        <!-- *ngIf="horliAux?.experiences == undefined || horliAux?.experiences[2].image == ''" -->
                        <div class="form-group col-md-4"
                            [ngClass]="!this.getExperiencesArray.at(2).get('image').valid ? 'error' : 'good'">
                            <div class="custom-dropzone d-flex align-items-center" ngx-dropzone [accept]="'image/*'"
                                [multiple]=false [disableClick]="true" (click)="file6.click()">
                                <ngx-dropzone-label>
                                    <div>
                                        <i class="fa fa-image icon" aria-hidden="true"></i>
                                        <h4 style="font-size: .9rem; padding: 0 30px;">ADJUNTA UNA FOTO DE TU
                                            EXPERIENCIA</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                    *ngIf="experience3 != undefined" [file]="experience3" [removable]="true"
                                    (removed)="onRemoveExperience(3)" style="margin: auto !important;">
                                </ngx-dropzone-image-preview>
                            </div>
                            <input type="file" class="file-input" accept="image/*"
                                (change)="fileChangeEvent($event,3,experiences);" #file6 />
                        </div>
                        <!-- <div *ngIf="horliAux?.experiences != undefined  && horliAux?.experiences[2].image != ''" class="form-group col-md-4">
                            <div class="custom-dropzone d-flex align-items-center">
                                <img [src]="horliAux?.experiences[2].image.url" class="img-achievement" alt="">
                            </div>
                        </div> -->
                        <div class="form-group col-md-8">
                            <input
                                [ngClass]="!this.getExperiencesArray.at(2).get('experience').valid ? 'error' : 'good'"
                                type="text" class="form-control my-2" placeholder="¿CUÁL FUE TU EXPERENCIA?"
                                formControlName="experience">
                            <textarea
                                [ngClass]="!this.getExperiencesArray.at(2).get('description').valid ? 'error' : 'good'"
                                formControlName="description" type="text" class="form-control" id="description" rows="4"
                                placeholder="CUÉNTANOS MÁS DE TU EXPERIENCIA"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <div>
                    <button (click)="toogleForm()" type="button" class="button-custom"><i class="fa fa-long-arrow-left"
                            aria-hidden="true"></i>
                        Volver </button>
                </div>
                <div>
                    <button type="button" (click)="saveChanges()" class="btn btn-link">Guardar cambios</button>
                    <button (click)="onSubmit()" type="button" class="button-custom">Siguiente <i
                            class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                    <!-- [disabled]="!achievementsForm.valid"  -->
                </div>
            </div>
        </div>
        <ng-container *ngIf="loader">
            <div class="loader">
                <div class="spinner-border text-danger" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-container>
    </section>
</form>

<ng-template #logros>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Ajustar imagen</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="3 / 4"
            format="jpg" [hideResizeSquares]="true" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
        <div>
            <img [src]="croppedImage" class="img-user" alt="">
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="modalRef.hide(); onSelectAchievement(actualAchiev)" class="btn btn-success">Aceptar</button>
        <button (click)="closeModal(1);" class="btn btn-danger">Cancelar</button>
    </div>
</ng-template>

<ng-template #experiences>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Ajustar imagen</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="10 / 9"
            format="jpg" [hideResizeSquares]="true" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
        <div>
            <img [src]="croppedImage" class="img-user" alt="">
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="modalRef.hide(); onSelectExperience(actualAchiev)" class="btn btn-success">Aceptar</button>
        <button (click)="closeModal(1);" class="btn btn-danger">Cancelar</button>
    </div>
</ng-template>

<ng-template #tooltipModal>
    <ng-container [ngSwitch]="tooltip">
        <ng-container *ngSwitchCase="'achievements'">
            <div class="modal-body tooltip-modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-12 col-lg-7">
                            <img src="../../../assets/img/tooltips/desktop/Logros.png" class="tooltip-img desktop-img"
                                alt="">
                            <img src="../../../assets/img/tooltips/movil/Logros.png" class="tooltip-img mobile-img"
                                alt="">
                        </div>
                        <div class="col-md-12 col-lg-5 tooltip-info">
                            <!-- <span aria-hidden="true" class="pull-right btn-close-modal" (click)="modalRef.hide()">&times;</span> -->
                            <img src="../../../assets/img/cancel.svg" class="pull-right btn-close-modal"
                                (click)="modalRef.hide()" alt="">
                            <p class="info-title">Logros</p>
                            <p>Compartir tus logros personales o profesionales, ayuda a demostrar a los demás networkers
                                lo que has alcanzado con tu esfuerzo y habilidad. </p>
                            <p>Esto refleja tu nivel como persona, networker y profesional.</p>
                            <p class="example-text"><span>Ejemplo actual basado en:</span> "Obi Wan Kenobi, Maestro
                                Jedi"</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button (click)="modalRef.hide()" class="btn btn-success">Entendido</button>
            </div> -->
        </ng-container>

        <ng-container *ngSwitchCase="'experiences'">
            <div class="modal-body tooltip-modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-12 col-lg-8">
                            <img src="../../../assets/img/tooltips/desktop/Experiencias.png"
                                class="tooltip-img desktop-img" alt="">
                            <img src="../../../assets/img/tooltips/movil/Experiencias.png"
                                class="tooltip-img mobile-img" alt="">
                        </div>
                        <div class="col-md-12 col-lg-4 tooltip-info">
                            <!-- <span aria-hidden="true" class="pull-right btn-close-modal" (click)="modalRef.hide()">&times;</span> -->
                            <img src="../../../assets/img/cancel.svg" class="pull-right btn-close-modal"
                                (click)="modalRef.hide()" alt="">
                            <p class="info-title">Experiencias enriquecedoras</p>
                            <p>Contar a los otros networkers sobre tus experiencias enriquecedoras, además de generar
                                confianza, refleja tu aprendizaje como persona y profesional.</p>
                            <p class="example-text"><span>Ejemplo actual basado en:</span> "Obi Wan Kenobi, Maestro
                                Jedi"</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button (click)="modalRef.hide()" class="btn btn-success">Entendido</button>
            </div> -->
        </ng-container>
    </ng-container>
</ng-template>