import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { readFile } from 'fs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { timeout } from 'rxjs/operators';
import { FormService } from 'src/app/services/form/form.service';
import Swal from 'sweetalert2';
// import { threadId } from 'worker_threads';

@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss']
})
export class StepFourComponent implements OnInit {

  @Output() next = new EventEmitter<string>();
  @Output() back = new EventEmitter<string>();
  achievementsForm: FormGroup;
  showForm = true;
  loader: boolean = false;
  id;
  horliAux;
  countAchievement1 = 360;
  countAchievement2 = 360;
  countAchievement3 = 360;
  countAchievementTitle1 = 50;
  countAchievementTitle2 = 50;
  countAchievementTitle3 = 50;

  imageReader: FileReader;

  achievement1: File = null;
  achievement2: File = null;
  achievement3: File = null;
  experience1: File;
  experience2: File;
  experience3: File;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  modalRef
  actualAchiev
  activeClick = false;
  activeClickSecond = false;
  currentStep;
  statusExp = true;
  statusAch = true;
  tooltip;

  constructor(
    public fb: FormBuilder,
    public formService: FormService,
    private _route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService
  ) {
    this.achievementsForm = this.fb.group({
      achievements: this.fb.array([
        this.fb.group({
          image: ['', [Validators.required]],
          achievement: ['', [Validators.required]],
          description: ['', [Validators.required]],
        }),
        this.fb.group({
          image: ['', [Validators.required]],
          achievement: ['', [Validators.required]],
          description: ['', [Validators.required]],
        }),
        this.fb.group({
          image: ['', [Validators.required]],
          achievement: ['', [Validators.required]],
          description: ['', [Validators.required]],
        }),
      ]),
      experiences: this.fb.array([
        this.fb.group({
          image: ['', [Validators.required]],
          experience: ['', [Validators.required]],
          description: ['', [Validators.required]],
        }),
        this.fb.group({
          image: ['', [Validators.required]],
          experience: ['', [Validators.required]],
          description: ['', [Validators.required]],
        }),
        this.fb.group({
          image: ['', [Validators.required]],
          experience: ['', [Validators.required]],
          description: ['', [Validators.required]],
        }),
      ]),
    })

    this.id = this._route.snapshot.paramMap.get('user');
  }

  hasError = (controlName: string, errorName: string) => {
    return this.achievementsForm.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {
    if (this.router.url != "/register") {
      this.id = this._route.snapshot.paramMap.get('user');
      this.formService.getByIDPromise(this.id).then((data: any) => {
        this.currentStep = data.currentStep;
        if (data.achievements) {
          this.formService.urlToBase64(data.achievements[0].image.url).then((file: File) => {
            this.achievement1 = file
          })
          this.formService.urlToBase64(data.achievements[1].image.url).then((file: File) => {
            this.achievement2 = file
          })
          this.formService.urlToBase64(data.achievements[2].image.url).then((file: File) => {
            this.achievement3 = file
          })
        }
        if (data.experiences) {
          this.formService.urlToBase64(data.experiences[0].image.url).then((file: File) => {
            this.experience1 = file
          })
          this.formService.urlToBase64(data.experiences[1].image.url).then((file: File) => {
            this.experience2 = file
          })
          this.formService.urlToBase64(data.experiences[2].image.url).then((file: File) => {
            this.experience3 = file
          })
        }
        this.horliAux = data;
        if (this.horliAux.achievements != undefined) {

          this.achievementsForm.controls.achievements.setValue(this.horliAux.achievements);
        }
        if (this.horliAux.experiences != undefined) {

          this.achievementsForm.controls.experiences.setValue(this.horliAux.experiences);
        }
      });
    }
  }

  async onSelectAchievement(position) {
    switch (position) {
      case 1:
        await this.onRemoveAchievement(position);
        setTimeout(() => {
          const newFile = new File([this.formService.dataURItoBlob(this.croppedImage)], '12ssdffgCualquiera', { type: 'image/jpeg' })
          this.achievement1 = newFile;
        }, 150)
        this.getAchievementsArray.at(0).get("image").clearValidators();
        this.getAchievementsArray.at(0).get("image").updateValueAndValidity();

        break;
      case 2:
        const func1 = await this.onRemoveAchievement(position);
        setTimeout(() => {
          const newFile = new File([this.formService.dataURItoBlob(this.croppedImage)], '12ssdffgCualquiera', { type: 'image/jpeg' })
          this.achievement2 = newFile;
        }, 150)
        this.getAchievementsArray.at(1).get("image").clearValidators();
        this.getAchievementsArray.at(1).get("image").updateValueAndValidity();
        break;
      case 3:
        const func2 = await this.onRemoveAchievement(position);

        setTimeout(() => {
          const newFile = new File([this.formService.dataURItoBlob(this.croppedImage)], '12ssdffgCualquiera', { type: 'image/jpeg' })
          this.achievement3 = newFile;
        }, 150)
        this.getAchievementsArray.at(2).get("image").clearValidators();
        this.getAchievementsArray.at(2).get("image").updateValueAndValidity();
        break;
    }
  }

  onRemoveAchievement(position) {
    return new Promise((resolve, reject) => {
      switch (position) {
        case 1:
          this.achievement1 = null;
          this.getAchievementsArray.at(0).get("image").setValidators([Validators.required]);
          this.getAchievementsArray.at(0).get("image").updateValueAndValidity();
          resolve(true)
          break;
        case 2:
          this.achievement2 = undefined;
          this.getAchievementsArray.at(1).get("image").setValidators([Validators.required]);
          this.getAchievementsArray.at(1).get("image").updateValueAndValidity();
          resolve(true)
          break;
        case 3:
          this.achievement3 = undefined;
          this.getAchievementsArray.at(2).get("image").setValidators([Validators.required]);
          this.getAchievementsArray.at(2).get("image").updateValueAndValidity();
          resolve(true)
          break;
      }
    });
  }

  async onSelectExperience(position) {
    switch (position) {
      case 1:
        const func = await this.onRemoveExperience(position);
        setTimeout(() => {
          const newFile = new File([this.formService.dataURItoBlob(this.croppedImage)], 'Cualquiera', { type: 'image/jpeg' })
          this.experience1 = newFile;
        }, 150)
        this.getExperiencesArray.at(0).get("image").clearValidators();
        this.getExperiencesArray.at(0).get("image").updateValueAndValidity();
        break;
      case 2:
        const func1 = await this.onRemoveExperience(position);
        setTimeout(() => {
          const newFile = new File([this.formService.dataURItoBlob(this.croppedImage)], 'Cualquiera', { type: 'image/jpeg' })
          this.experience2 = newFile;
        }, 150)
        this.getExperiencesArray.at(1).get("image").clearValidators();
        this.getExperiencesArray.at(1).get("image").updateValueAndValidity();
        break;
      case 3:
        const func2 = await this.onRemoveExperience(position);
        setTimeout(() => {
          const newFile = new File([this.formService.dataURItoBlob(this.croppedImage)], 'Cualquiera', { type: 'image/jpeg' })
          this.experience3 = newFile;
        }, 150)
        this.getExperiencesArray.at(2).get("image").clearValidators();
        this.getExperiencesArray.at(2).get("image").updateValueAndValidity();

        break;
    }
  }

  onRemoveExperience(position) {
    switch (position) {
      case 1:
        this.experience1 = undefined;
        this.getExperiencesArray.at(0).get("image").setValidators([Validators.required]);
        this.getExperiencesArray.at(0).get("image").updateValueAndValidity();
        break;
      case 2:
        this.experience2 = undefined;
        this.getExperiencesArray.at(1).get("image").setValidators([Validators.required]);
        this.getExperiencesArray.at(1).get("image").updateValueAndValidity();
        break;
      case 3:
        this.experience3 = undefined;
        this.getExperiencesArray.at(2).get("image").setValidators([Validators.required]);
        this.getExperiencesArray.at(2).get("image").updateValueAndValidity();
        break;
    }
  }

  toogleForm() {
    if (!this.achievementsForm.controls['achievements'].valid) {
      this.activeClick = true;
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Favor de llenar los campos faltantes.',
        confirmButtonText: 'Entendido',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: "modal-warning"
        }
      })
    } else {
      this.showForm = !this.showForm;
    }
  }

  goBack() {
    this.back.emit("prev")
  }

  get getAchievementsArray() {
    return this.achievementsForm.get("achievements") as FormArray;
  }

  get getExperiencesArray() {
    return this.achievementsForm.get("experiences") as FormArray;
  }

  onSubmit() {
    if (this.achievementsForm.valid) {
      this.loader = true;
      this.auxFunction().then(() => {
        this.formService.addHighlights(this.id, this.achievementsForm.value).then(data => {
          if (this.formService.getStep == 4) {
            this.formService.updateStep(this.id, this.formService.getStep + 1)
          }
          this.next.emit("next")
          this.loader = false;
        });
      });
    } else {
      this.activeClickSecond = true;
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Favor de llenar los campos faltantes.',
        confirmButtonText: 'Entendido',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: "modal-warning"
        }
      })
      // alert("Favor de llenar los campos faltantes.");
    }
  }

  saveChanges() {
    this.loader = true;
    this.auxFunction().then(() => {
      this.formService.addHighlights(this.id, this.achievementsForm.value).then(data => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Cambios guardados',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            container: "modal-warning"
          }
        }).then(() => {
          this.loader = false
        })
      });
    });
  }

  uploadImage(file, id, control) {
    return new Promise<any>((resolve, reject) => {
      let aux = control.value;
      if (file != undefined) {
        if (aux) {
          let arr = [];
          arr.push(control.value)
          this.formService.cleanStorage(arr);

          control.setValue("");
        }
        this.formService.uploadImage(file, id).then(data => {
          control.setValue(data);
          resolve(data)
        })
      } else {
        resolve("vacio")
      }
    })
  }

  // Agrego la siguiente función
  onKey(event, group, field, max) {
    switch (group) {
      case 1:
        if (field == "input") {
          if (event.target.value.length > max) {
            event.target.value = event.target.value.substr(0, max);
            this.countAchievementTitle1 = 0
          } else {
            this.countAchievementTitle1 = (max - event.target.value.length)
          }
        } else {
          this.countAchievement1 = (max - event.target.value.length)
        }
        break;
      case 2:
        if (field == "input") {
          if (event.target.value.length > max) {
            event.target.value = event.target.value.substr(0, max);
            this.countAchievementTitle2 = 0
          } else {
            this.countAchievementTitle2 = (max - event.target.value.length)
          }
        } else {
          this.countAchievement2 = (max - event.target.value.length)
        }
        break;
      case 3:
        if (field == "input") {
          if (event.target.value.length > max) {
            event.target.value = event.target.value.substr(0, max);
            this.countAchievementTitle3 = 0
          } else {
            this.countAchievementTitle3 = (max - event.target.value.length)
          }
        } else {
          this.countAchievement3 = (max - event.target.value.length)
        }
        break;

      default:
        break;
    }
  }

  async auxFunction() {
    await new Promise<any>((resolve, reject) => {

      this.uploadImage(this.achievement1, this.id, this.getAchievementsArray.at(0).get("image")).then(data => {
        this.uploadImage(this.achievement2, this.id, this.getAchievementsArray.at(1).get("image")).then(data => {
          this.uploadImage(this.achievement3, this.id, this.getAchievementsArray.at(2).get("image")).then(data => {
            this.uploadImage(this.experience1, this.id, this.getExperiencesArray.at(0).get("image")).then(data => {
              this.uploadImage(this.experience2, this.id, this.getExperiencesArray.at(1).get("image")).then(data => {
                this.uploadImage(this.experience3, this.id, this.getExperiencesArray.at(2).get("image")).then(data => {
                  resolve("termine")
                })
              })
            })
          })
        })
      })
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg', backdrop: 'static' });
  }

  closeModal(modalId?: number) {
    this.modalService.hide(modalId);
  }

  openTooltipModal(template: TemplateRef<any>, tooltipType) {
    this.tooltip = tooltipType;
    this.modalRef = this.modalService.show(template, { class: 'modal-xl', backdrop: 'static' });
  }

  fileChangeEvent(event: any, num:number, temp: TemplateRef<any>): void {
    console.log(event.srcElement.files[0].size)
    if(event.srcElement.files[0].size > 8000000){
      this.imgSizeError()
      return;
    }
    this.imageChangedEvent = event;
    this.actualAchiev = num
    this.openModal(temp);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image?: HTMLImageElement) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  imgSizeError() {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Tamaño excedido',
      text: 'La imagen excede el tamaño permitido (8MB)',
      confirmButtonText: 'Entendido',
      customClass: {
        container: "modal-warning"
      }
    })
  }

}
