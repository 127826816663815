<section>
    <div class="custom-container">
        <div class="row header-info" style="width: 100%; margin: 0;">
            <div class="col-lg-6 col-sm-12 info" >
                <div class="">
                    <div class="logo">
                        <img [src]="horli?.companyLogo.url" alt="">
                    </div>
                    <div class="info-user">
                        <p class="">
                            Hola, mi nombre es
                        </p>
                        <div class="col-12 name-user">
                            <h1 *ngIf="!horli?.lastName">{{ horli?.name }}</h1>
                            <h1 *ngIf="horli?.lastName">{{ horli?.name }} {{ horli?.lastName }}</h1>
                            <!-- <div class="rectangle"></div> -->
                        </div>
                        <div class="col-12">
                            <p class="position">{{ horli?.job }}</p>
                            <p class="company">{{ horli?.companyName }}</p>                            
                        </div>
                        <div class="col-12 container-button">
                            <button class="btn custom-button btn-lg" [routerLink]="['/my-networking/'+id]">Conoce más de mí</button>                                                      
                        </div>
                    </div>
                </div>                
            </div>
            <div class="col-lg-6 col-sm-12 imagen order-lg-last order-md-first order-first">
                <div class="logo movil">
                    <img [src]="horli?.companyLogo.url" alt="">
                </div>
                <div class="container-image">
                    <div class="user-image">
                        <img [src]="horli?.userPhoto.url" class="img-user" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-container *ngIf="loader">
    <div class="loader">
        <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</ng-container>