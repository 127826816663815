<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12 title">
                <img src="assets/img/star.png" alt="">
                <h1>Experiencias Enriquecedoras</h1>
            </div>
            <div class="col-md-4 item" data-aos="fade-right">
                <div class="img" >
                    <img [src]="horli?.experiences[0].image.url" alt="">
                </div>
                <h2> {{ horli?.experiences[0].experience }} </h2>
                <p> {{ horli?.experiences[0].description }} </p>
            </div>
            <div class="col-md-4 item" data-aos="fade-up">
                <div class="img">
                    <img [src]="horli?.experiences[1].image.url" alt="">
                </div>
                <h2> {{ horli?.experiences[1].experience }} </h2>
                <p> {{ horli?.experiences[1].description }} </p>
            </div>
            <div class="col-md-4 item" data-aos="fade-left">
                <div class="img">
                    <img [src]="horli?.experiences[2].image.url" alt="">
                </div>
                <h2> {{ horli?.experiences[2].experience }} </h2>
                <p> {{ horli?.experiences[2].description }} </p>
            </div>
        </div>
    </div>
</section>