import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MetatagsService } from 'src/app/services/metatags/metatags.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  ruta = this.router.url
  constructor(
    private router: Router,
    public metatags: MetatagsService) { 

    router.navigateByUrl('/pages/not-found')
    
    metatags.updateMetaTags(this.ruta);

  }
  ngOnInit(): void {
  }

}
