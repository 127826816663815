<section>
    <div class="container">
        <div class="content">
            <form [formGroup]="confirmForm" (keyup.enter)="confirmMail()">
                <div class="row">
                    <div class="form-group col-md-12">
                        <h1>Verificación</h1>
                        <label for="email" class="col-form-label">Favor de ingresar el correo que ingresaste en tu registro para proceder la edición de tu hoja de Networking</label>
                        <div>
                            <input formControlName="email" type="email" class="form-control" id="email">
                        </div>
                    </div>
                </div>
            </form>
            <button (click)="confirmMail()" class="btn-pay" [disabled]="!confirmForm.valid" type="button">Acceder</button>
        </div>
    </div>
</section>
<ng-container *ngIf="loader">
    <div class="loader">
        <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</ng-container>