import { Component, Input, OnInit } from '@angular/core';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss']
})
export class GeneralInfoComponent implements OnInit {

  @Input() horli: any;
  user = true;
  constructor() { }

  ngOnInit(): void {

  }

  change(f) {
    this.user = f;
  }

}
