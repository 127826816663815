<app-general-info [horli]="horli"></app-general-info>

<app-experiences [horli]="horli"></app-experiences>

<app-achievements [horli]="horli"></app-achievements>

<app-myself [horli]="horli"></app-myself>

<app-company-info [horli]="horli"></app-company-info>

<ng-container *ngIf="loader">
    <div class="loader">
        <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</ng-container>