import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConektaService {

  constructor(
    private http: HttpClient
  ) { }

  createOrder(customer_info, line_items, token) {
    const headers = { 'Authorization': 'Bearer ai5kaWF6QGRhcHBlcnRlY2hub2xvZ2llcy5jb206REFwcGVyTWVuc2FqZWRlQXV0ZW50aWNhY2lvbjEyMzE=' }
    const body = {
      customer_info: customer_info,
      line_items: line_items,
      token_id: token
    }

    return this.http.post<any>('https://horli-conekta.herokuapp.com/charge', body, { headers });
    // return this.http.post<any>('http://localhost:3000/charge', body, { headers });
  }
  
}
