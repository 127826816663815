<section style="overflow-y: auto;">
    <div class="container custom-container">
        <div class="col-md-12 header">
            <h1>Confirmación de Información</h1>
            <p>Importante revisar cuidadosamente toda la información ya que esta no podrá ser modificada.</p>
        </div>
        <div class="col-lg-12 row personal-info">
            <div class="col-lg-7">
                <label>NOMBRE COMPLETO: </label> <span> {{ horli?.name }} {{horli?.lastName}} </span>
                <hr>
                <label>PUESTO: </label> <span> {{ horli?.job }} </span>
                <hr>
                <label>CORREO ELECTRÓNICO: </label> <span> {{ horli?.email }} </span>
                <hr>
                <label>TELÉFONO: </label> <span> {{ horli?.phone }} </span>
                <hr>
            </div>
            <div class="col-lg-5">
                <img [src]="horli?.userPhoto?.url" class="user-photo" alt="">
            </div>
            <div class="col-lg-12">
                <label>ACERCA DE MÍ: </label><br>
                <span>
                    {{ horli?.aboutMe }}
                </span>
                <hr>
            </div>
            <div class="col-lg-12">
                <label>INTERESES: </label><br>
                <span>
                    {{ horli?.interests }}
                </span>

                <hr>
            </div>
            <div class="col-lg-6">
                <label>HABILIDADES: </label>
                <ul>
                    <li *ngFor="let skill of horli?.skills">
                        {{ skill.skill }}
                    </li>
                </ul>
            </div>
            <div class="col-lg-6">
                <label>PASATIEMPOS: </label>
                <ul>
                    <li *ngFor="let hobbie of horli?.hobbies">
                        {{ hobbie.hobbie }}
                    </li>
                </ul>
            </div>
            <hr>
            <div class="col-lg-7">
                <label>CIUDADES EN LAS QUE HAS VIVIDO: </label>
                <ul>
                    <li *ngFor="let city of horli?.cities">
                        {{ city.city }}
                    </li>
                </ul>
            </div>
            <hr>
        </div>
        <div class="col-lg-12 row photos">
            <h2>MIS FOTOGRAFÍAS</h2>
            <div class="col-lg-3" *ngFor="let hobbiePhoto of horli?.hobbiesPhotos">
                <img [src]="hobbiePhoto.url" class="img-hobbie" alt="">
            </div>
        </div>
        <div class="col-lg-12 photos-achievement">
            <hr>
            <h2>LOGROS: </h2>
            <div>
                <div class="row achievement" *ngFor="let achievement of horli?.achievements">
                    <ng-container *ngIf="achievement?.image?.url">
                        <div class="col-md-5 col-lg-4 col-xl-3">
                            <img [src]="achievement.image?.url" class="img-fluid img-achievement" alt="">
                        </div>
                        <div class="col-md-7 col-lg-8 col-xl-9">
                            <span> {{ achievement.achievement }} </span>
                            <p>
                                {{ achievement.description }}
                            </p>
                        </div>
                    </ng-container>
                </div>
            </div>
            <hr>
            <h2>EXPERIENCIAS ENRIQUECEDORAS: </h2>
            <div>
                <div class="row achievement" *ngFor="let experience of horli?.experiences">
                    <div class="col-lg-4 col-xl-3">
                        <img [src]="experience.image?.url" class="img-fluid img-achievement" alt="">
                    </div>
                    <div class="col-lg-8 col-xl-9">
                        <span> {{ experience.experience }} </span>
                        <p>
                            {{ experience.description }}
                        </p>
                    </div>
                </div>
            </div>
            <hr>
        </div>
        <div class="col-lg-12 company-info row">
            <div class="col-lg-7">
                <label>NOMBRE DE LA EMPRESA: </label> <span> {{ horli?.companyName }} </span>
                <hr>
                <label>GIRO DE LA EMPRESA: </label> <span> {{ horli?.companyType }} </span>
                <hr>
                <label>SOBRE MI EMPRESA: </label> <br>
                <span> {{ horli?.companyDescription }} </span>
                <hr>
                <!-- <div class="col-lg-4"> -->
                <label>EXPERIENCIAS LABORALES PASADAS: </label>
                <ul>
                    <div *ngFor="let item of horli?.oldJobs">
                        <li *ngIf="item.oldJob != '' ">
                            {{ item.oldJob }}
                        </li>
                    </div>
                </ul>
                <!-- </div> -->
            </div>
            <div class="col-lg-5">
                <ng-container *ngIf="horli?.companyLogo?.url && horli?.companyImage?.url">
                    <div class="row">
                        <div class="col-6 col-lg-12">
                            <img [src]="horli?.companyLogo?.url" class="user-photo" alt="">
                        </div>
                        <div class="col-6 col-lg-12">
                            <img [src]="horli?.companyImage.url" class="user-photo" alt="">
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-lg-12 row general-info" style="margin-top: 25px;">
            <!-- <div class="col-lg-12">                 
                <label>LO QUE HAGO: </label><br>
                <span>                    
                    {{ horli?.whatIDo }}
                </span>
                
                <hr>
            </div> -->
            <div class="col-lg-6">
                <label>SERVICIOS: </label>
                <ng-container *ngFor="let item of horli?.services">
                    <br>
                    <span>- {{ item.service }} </span>
                </ng-container>
            </div>
            <div class="col-lg-6 my-3">
                <label>ESFERA DE CONTACTOS: </label>
                <ng-container *ngFor="let contact of horli?.contacts">
                    <br>
                    <span>- {{ contact.contact }} </span>
                </ng-container>
                <!-- <ul>
                    <li *ngFor="let contact of horli?.contacts">
                        {{ contact.contact }}
                    </li>
                </ul> -->
            </div>
            <hr>
            <div class="col-lg-6">
                <label>REFERENCIA IDEAL: </label>
                <ng-container *ngFor="let item of horli?.companyReference">
                    <br>
                    <span>- {{ item.reference }} </span>
                </ng-container>
            </div>
            <div class="col-lg-6 my-3">
                <label>ULTIMOS CLIENTES: </label>
                <ng-container *ngFor="let client of horli?.clients">
                    <br>
                    <span>- {{ client.client }} </span>
                </ng-container>
                <!-- <ul>
                    <li *ngFor="let client of horli?.clients">
                        {{ client.client }}
                    </li>
                </ul> -->
            </div>
            <hr>
            <!-- <div class="col-lg-12">
                <label>OBJETIVO PERSONAL: </label><br>
                <span>                    
                    {{ horli?.personalGoals }}
                </span>
                
                <hr>
            </div>
            <div class="col-lg-12">            
                <label>OBJETIVO PROFESIONAL:</label><br>
                <span>                    
                    {{ horli?.professionalGoals }}
                </span>
                
                <hr>
            </div> -->
        </div>
    </div>
    <div class="buttons">
        <div>
            <button type="button" (click)="goBack()" class="button-custom first"><i class="fa fa-long-arrow-left"
                    aria-hidden="true"></i>
                Modificar datos </button>
        </div>
        <div>
            <button type="button" (click)="finalizeForm()" class="button-custom">Finalizar <i
                    class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
        </div>
    </div>
</section>