import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss']
})
export class AchievementsComponent implements OnInit {

  @Input() horli: any;
  constructor() { }

  ngOnInit(): void {
  }

}
