import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MetatagsService {

  url = "https://minetworking.com/";

  constructor(private meta: Meta, private title: Title, public router: Router) { }

  updateMetaTags(url, data?, id?) {
    let ruta = this.router.url;
    switch (url) {
      case '/':

        this.title.setTitle("Networking - Tu nueva hoja de Networking ¡Feliz conexión!");

        this.meta.updateTag({ property: "og:title", content: this.url + "Networking - Tu nueva hoja de Networking ¡Feliz conexión!" });
        this.meta.updateTag({ property: "og:description", content: "¡Revoluciona y mejora tu hoja de Networking! La introducción perfecta para una importante presentación, una presentación de conexión y negocios con tus contactos." });

        this.meta.updateTag({ name: "twitter:title", content: "Networking - Tu nueva hoja de Networking ¡Feliz conexión!" });
        this.meta.updateTag({ name: "twitter:description", content: "¡Revoluciona y mejora tu hoja de Networking! La introducción perfecta para una importante presentación, una presentación de conexión y negocios con tus contactos." });

        break;
      case '/register':

        this.title.setTitle("Networking - Formulario para tu nueva hoja Networking");

        this.meta.updateTag({ property: "og:title", content: "Networking - Formulario para tu nueva hoja Networking" });
        this.meta.updateTag({ property: "og:description", content: "Completa tu formulario para contar con tu nueva hoja de Networking y tener la introducción perfecta para una importante presentación ¡feliz conexión!" });

        this.meta.updateTag({ name: "twitter:title", content: "Networking - Formulario para tu nueva hoja de Networking" });
        this.meta.updateTag({ name: "twitter:description", content: "Completa tu formulario para contar con tu nueva hoja de Networking y tener la introducción perfecta para una importante presentación ¡feliz conexión!" });

        break;
      case 'presentation':

        let name;
        if (data.lastName) {
          name = data.name.split(" ")[0] + " " + data.lastName.split(" ")[0]
        } else {
          name = data.name
        }

        this.title.setTitle("Networking - " + name);

        this.meta.updateTag({ property: "og:title", content: "Networking - " + name });
        this.meta.updateTag({ property: "og:description", content: data.job + " / " + data.companyName + " - " + data.aboutMe.substr(0, 111) + "..." });
        this.meta.updateTag({ property: "og:url", content: this.url + url + '/' + id });
        this.meta.updateTag({ property: "og:image", content: data.userPhoto.url });

        this.meta.updateTag({ name: "twitter:title", content: "Networking - " + name });
        this.meta.updateTag({ name: "twitter:description", content: data.job + " / " + data.companyName + " - " + data.aboutMe.substr(0, 111) + "..." });
        this.meta.updateTag({ name: "twitter:url", content: this.url + url + '/' + id });
        this.meta.updateTag({ property: "twitter:image", content: data.userPhoto.url });

        break;

      case 'my-networking':

        let nameHorli;
        if (data.lastName) {
          nameHorli = data.name.split(" ")[0] + " " + data.lastName.split(" ")[0]
        } else {
          nameHorli = data.name
        }

        this.title.setTitle("Networking - " + nameHorli);

        this.meta.updateTag({ property: "og:title", content: "Networking - " + nameHorli });
        this.meta.updateTag({ property: "og:description", content: data.job + " / " + data.companyName + " - " + data.aboutMe.substr(0, 111) + "..." });
        this.meta.updateTag({ property: "og:url", content: this.url + url + '/' + id });
        this.meta.updateTag({ property: "og:image", content: data.userPhoto.url });

        this.meta.updateTag({ name: "twitter:title", content: "Networking - " + nameHorli });
        this.meta.updateTag({ name: "twitter:description", content: data.job + " / " + data.companyName + " - " + data.aboutMe.substr(0, 111) + "..." });
        this.meta.updateTag({ name: "twitter:url", content: this.url + url + '/' + id });
        this.meta.updateTag({ property: "twitter:image", content: data.userPhoto.url });

        break;
      case '/page/not-found':

        this.title.setTitle("Networking");

        this.meta.updateTag({ property: "og:title", content: "Networking, pagina no disponible" });
        this.meta.updateTag({ property: "og:description", content: "La pagina a la que estas intentando acceder no esta disponible." });

        this.meta.updateTag({ name: "twitter:title", content: "Networking, pagina no disponible" });
        this.meta.updateTag({ name: "twitter:description", content: "La pagina a la que estas intentando acceder no esta disponible." });
        break;
      case 'confirm':

        this.title.setTitle("Networking");

        this.meta.updateTag({ property: "og:title", content: "Networking" });
        this.meta.updateTag({ property: "og:description", content: "Networking" });

        this.meta.updateTag({ name: "twitter:title", content: "Networking" });
        this.meta.updateTag({ name: "twitter:description", content: "Networking" });

        break;
      default:
        break;
    }
  }

}
