import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/services/form/form.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-step-six',
  templateUrl: './step-six.component.html',
  styleUrls: ['./step-six.component.scss']
})
export class StepSixComponent implements OnInit {

  @Output() back = new EventEmitter<string>();
  id;
  horli;

  constructor(public formService: FormService, private _route: ActivatedRoute, private router: Router) {
    if (this.router.url != "/register") {
      this.id = this._route.snapshot.paramMap.get('user');
      this.formService.getByID(this.id).subscribe(data => {
        this.horli = data;
      });
    }
  }

  ngOnInit(): void {
  }

  goBack() {
    this.back.emit("two")
  }

  finalizeForm(){

    Swal.fire({
      title: '¿Estás seguro de finalizar el formulario?',
      text: "Esta acción, NO se podrá revertir",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, finalizar',
      cancelButtonText: 'No, cancelar',
      customClass: {
        container: "modal-warning"
      }
    }).then((result) => {
      if (result.value) {
        
        let aux = this.formService.finalizeForm(this.id)
        aux.then((result) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Formulario finalizado',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              container: "modal-warning"
            }
          }).then( () => {
            this.router.navigateByUrl('/'+this.id)
          })
        })
      }
    })
  }

}
