import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  horliDocRef: AngularFirestoreDocument;
  downloadURL: Observable<string>;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  images = [];
  horli$: Observable<any>;
  imgs = []
  currentStep;

  constructor(private afs: AngularFirestore, public storage: AngularFireStorage) { }

  get getStep() {
    return this.currentStep;
  }
  setStep(value) {
    this.currentStep = value;
  }

  addPersonalInfo(uid, data,cities, hobbies, skills) {
    return new Promise<any>((resolve, reject) => {
      this.horliDocRef = this.afs.collection('horlies').doc(uid);
      if(data.hobbiesPhotos == undefined){
        this.horliDocRef.update({
          aboutMe: data.aboutMe || "",
          interests: data.interests || "",
          userPhoto: data.userPhoto || "",
          skills: skills || "",
          hobbies: hobbies || "",
          cities: cities || "",
        }).then((data) => {
          resolve(data)
        })
      } else {
        this.horliDocRef.update({
          aboutMe: data.aboutMe || "",
          interests: data.interests || "",
          userPhoto: data.userPhoto || "",
          hobbiesPhotos: data.hobbiesPhotos,
          skills: skills || "",
          hobbies: hobbies || "",
          cities: cities || "",
        }).then((data) => {
          resolve(data)
        })
      }
      
    })
  }

  updateStep(uid, step) {
    return new Promise<any>((resolve, reject) => {
      this.horliDocRef = this.afs.collection('horlies').doc(uid);
      this.horliDocRef.update({
        currentStep: step
      }).then((data) => {
        resolve(data)
      })
    })
  }

  addHighlights(uid, data) {
    return new Promise<any>((resolve, reject) => {
      this.horliDocRef = this.afs.collection('horlies').doc(uid);
      this.horliDocRef.update({
        achievements: data.achievements,
        experiences: data.experiences,
      }).then((data) => {
        resolve(data)
      })
    })
  }

  addProfessionalInfo(uid, data, oldJobs, services, companyReference, clients, contacts) {
    return new Promise<any>((resolve, reject) => {
      this.horliDocRef = this.afs.collection('horlies').doc(uid);
      this.horliDocRef.update({
        whatIDo: data.whatIDo,
        personalGoals: data.personalGoals,
        professionalGoals: data.professionalGoals,
        contacts: contacts,
        clients: clients,
        oldJobs: oldJobs,
        companyName: data.companyName,
        companyType: data.companyType,
        companyDescription: data.companyDescription,
        companyReference: companyReference,
        companyImage: data.companyImage,
        companyLogo: data.companyLogo,
        services: services
      }).then((data) => {
        resolve(data)
      })
    })
  }

  uploadImage(file, id) {
    return new Promise<any>((resolve, reject) => {
      let n = Math.random().toString(36).substring(2);
      const filePath = "horlies/" + id + "/" + n + '.jpg';
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file).then(res => {
        this.downloadURL = fileRef.getDownloadURL();
        this.downloadURL.subscribe(url => {
          let data = { url: url, path: filePath }
          resolve(data)
        })
      })
    })
  }

  cleanStorage(img){
    if(img[0] != "vacio"){
      img.forEach(i => {
        this.storage.ref(i.path).delete();
      });
    }
  } 

  uploadArrayImage(files, id) {
    return new Promise<any>((resolve, reject) => {
      let images = [];
      files.forEach(element => {
        let n = Date.now();
        let filePath = "horlies/" + id + "/" + n + '.jpg';
        let ref = this.storage.ref("horlies/" + id + "/" + n);
        this.task = this.storage.upload(filePath, element);
        this.task.snapshotChanges().pipe(
          finalize(() => {
            this.downloadURL = ref.getDownloadURL()
            this.downloadURL.subscribe(url => {
              console.log('img saved');
              images.push(url);
            });
          })
        ).subscribe();
      });
      resolve(images)
    })
  }

  getByID(uid) {
    this.horliDocRef = this.afs.collection('horlies').doc(uid);
    this.horli$ = this.horliDocRef.valueChanges();
    return this.horli$
  }

  getByIDPromise(uid){
    return new Promise((resolve, reject) => {
      this.horliDocRef = this.afs.collection('horlies').doc(uid);
      this.horli$ = this.horliDocRef.valueChanges();
      this.horli$.subscribe(data => {
        resolve(data)
      })
    });
  }

  finalizeForm(uid){
    return new Promise<any>((resolve, reject) => {
      this.horliDocRef = this.afs.collection('horlies').doc(uid);
      this.horliDocRef.update({
        complete: true,
      }).then((data) => {
        resolve(data)
      })
    })
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  urlToBase64(url: string) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d');
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        var imgB = canvas.toDataURL('image/jpeg')
        const imagemAsFile = new File([this.dataURItoBlob(imgB)], 'Cualquiera', { type: 'image/jpeg' });
        resolve(imagemAsFile)
      };
      img.onerror = error => reject(error);
    })
  }

}
