import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as AOS from 'aos';
import { FormService } from 'src/app/services/form/form.service';
// import { MetatagsService } from 'src/app/services/metatags/metatags.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MetatagsService } from 'src/app/services/metatags/metatags.service';

@Component({
  selector: 'app-my-horli',
  templateUrl: './my-horli.component.html',
  styleUrls: ['./my-horli.component.scss']
})
export class MyHorliComponent implements OnInit {

  id;
  horli;
  loader: boolean = true;
  ruta = this.router.url


  @Inject(PLATFORM_ID) private platformId: any

  constructor(public formService: FormService, private _route: ActivatedRoute, private router: Router,
    private metatags: MetatagsService,
    private meta: Meta, private title: Title) {

    if (isPlatformBrowser(this.platformId)) {
      document.body.style.overflow = 'hidden';
    }
    this.id = this._route.snapshot.paramMap.get('user');
    this.formService.getByIDPromise(this.id).then((data: any) => {
      if (data == undefined) {
        this.router.navigateByUrl('/page/not-found')
      }
      if (data.payIt == false || data.complete == false) {
        this.router.navigateByUrl('/' + this.id + '/confirm')
      }
      this.horli = data;
      setTimeout(() => {
        this.loader = false;
      }, 500);
      if (isPlatformBrowser(this.platformId)) {
        document.body.style.overflow = '';
      }
      let rutasplit = this.ruta.split("/")
      metatags.updateMetaTags(rutasplit[1], data, rutasplit[2]);
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      AOS.init({
        once: true,
        disable: 'mobile',
        offset: 300,
        duration: 2000, // values from 0 to 3000, with step 50ms
      })
    }, 500);
  }

}
