<section>
    <h1>Información de Contacto</h1>
    <div class="form">
        <form [formGroup]="contactForm">
            <div class="form-group row">
                <label for="name" class="col-sm-5 col-form-label">NOMBRE(S)</label>
                <div class="col-sm-7">
                    <input formControlName="first_name" (keydown)="keyPress($event, 'first_name')" type="text" class="form-control" id="name">
                    <ng-container *ngIf="!contactForm.controls['first_name']?.valid && contactForm.controls['first_name']?.touched">
                        <p class="caption status-danger" *ngIf="hasError('first_name', 'required')">
                            El campo es requerido
                        </p>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label for="last_name" class="col-sm-5 col-form-label">APELLIDOS</label>
                <div class="col-sm-7">
                    <input formControlName="last_name" (keydown)="keyPress($event, 'last_name')" type="text" class="form-control" id="last_name">
                    <ng-container *ngIf="!contactForm.controls['last_name']?.valid && contactForm.controls['last_name']?.touched">
                        <p class="caption status-danger" *ngIf="hasError('last_name', 'required')">
                            El campo es requerido
                        </p>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label for="job" class="col-sm-5 col-form-label">PUESTO</label>
                <div class="col-sm-7">
                    <input formControlName="job" type="text" class="form-control" id="job">
                    <ng-container *ngIf="!contactForm.controls['job']?.valid && contactForm.controls['job']?.touched">
                        <p class="caption status-danger" *ngIf="hasError('job', 'required')">
                            El campo es requerido
                        </p>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label for="email" class="col-sm-5 col-form-label">CORREO ELECTRÓNICO</label>
                <div class="col-sm-7">
                    <input formControlName="email" type="mail" class="form-control" id="email">
                    <ng-container *ngIf="!contactForm.controls['email']?.valid && contactForm.controls['email']?.touched">
                        <p class="caption status-danger" *ngIf="hasError('email', 'required')">
                            El campo es requerido
                        </p>
                        <p class="caption status-danger" *ngIf="hasError('email', 'pattern')">
                            Formato de correo incorrecto (example@mail.com)
                        </p>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row">
                <label for="phone" class="col-sm-5 col-form-label">TELÉFONO</label>
                <div class="col-sm-7">
                    <input formControlName="phone" mask="(000) 000 0000" type="tel" class="form-control" id="phone">
                    <ng-container *ngIf="!contactForm.controls['phone']?.valid && contactForm.controls['phone']?.touched">
                        <p class="caption status-danger" *ngIf="hasError('phone', 'required')">
                            El campo es requerido
                        </p>
                        <p class="caption status-danger" *ngIf="hasError('phone', 'minlength')">
                            El número de teléfono móvil (celular) debe contener 10 dígitos
                        </p>
                    </ng-container>
                </div>
            </div>
        </form>
        <div class="buttons">
            <button [routerLink]="['']" type="button"><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Volver al
                sitio</button>
            <button (click)="goForward()" type="button" [disabled]="!contactForm.valid">Siguiente <i
                    class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
            <!-- [disabled]="!contactForm.valid" -->
        </div>
    </div>
</section>