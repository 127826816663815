import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(public http: HttpClient) { }

  sendConfirmMail(info){
    let data = {
      url:info.url,
      email:info.email
    }
    const headers = { 'Authorization': 'Bearer ai5kaWF6QGRhcHBlcnRlY2hub2xvZ2llcy5jb206REFwcGVyTWVuc2FqZWRlQXV0ZW50aWNhY2lvbjEyMzE=' }
    return this.http.post<any>('https://horli-conekta.herokuapp.com/sendcontact', data, { headers })
    // return this.http.post<any>('http://localhost:3000/sendcontact', data, { headers })
  }

}
