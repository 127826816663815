<section>
    <div class="container">
        <div class="row">
            <div class="col">
                <h1>¡Comienza a construir tu hoja de Networking!</h1>
                <p class="before">Por solo <span>MXN499.00</span>/año</p>
                <h2 class="after">MXN399.00<span>/año</span></h2>
                <div class="d-flex justify-content-center">
                    <button class="btn custom-button  btn-lg" [routerLink]="['/register']">Comencemos <i class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
    </div>
</section>
