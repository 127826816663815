<section>
    <div class="present container">
        <div class="person">
            <h1 *ngIf="!horli?.lastName">{{ horli?.name }}</h1>
            <h1 *ngIf="horli?.lastName">{{ horli?.name }} {{ horli?.lastName }}</h1>
            <p>{{ horli?.job }}</p>
            <p class="name-company">{{ horli?.companyName }}</p>
        </div>
        <img [src]="horli?.companyLogo.url" class="img-fluid company" alt="">
    </div>
    <div class="info container">
        <div class="person" data-aos="fade-right">
            <img [src]="horli?.userPhoto.url" [ngClass]="user ? 'activeImg' : 'hidden'" class="img-fluid" alt="">
            <img [src]="horli?.companyImage.url" [ngClass]="!user ? 'activeImg' : 'hidden'" class="img-fluid" alt="">
        </div>
        <div class="details" id="tabs-present" data-aos="fade-left">
            <tabset #staticTabs>
                <tab heading="Sobre mí" (selectTab)="change(true)">
                    <p>
                        {{ horli?.aboutMe }}
                    </p>
                    <div class="list">
                        <h2>Habilidades</h2>
                        <ul>
                            <li *ngFor="let skill of horli?.skills">{{skill.skill}}</li>
                        </ul>
                    </div>
                </tab>
                <tab heading="Sobre mi empresa" (selectTab)="change(false)">
                    <p>
                        {{ horli?.companyDescription }}
                    </p>
                    <div class="list">
                        <h2>Experiencias Laborales Pasadas</h2>
                        <ul>
                            <li *ngFor="let job of horli?.oldJobs">{{job.oldJob}}</li>
                        </ul>
                    </div>
                </tab>
                <!-- <tab heading="Mis objetivos">
                    <p>Objetivos Personales</p>
                    <p>
                        {{ horli?.personalGoals }}
                    </p>
                    <p>Objetivos Profesionales</p>
                    <p>
                        {{ horli?.professionalGoals }}
                    </p>
                </tab> -->
            </tabset>
        </div>
    </div>
</section>