<section>
    <div class="container">
        <div class="row">
            <div class="col-md-6 left" data-aos="fade-up" data-aos-easing="linear">
                <div class="title">
                    <img src="assets/img/person.svg" alt="">
                    <h1>Mi Persona</h1>
                </div>
                <div class="col-md-12">
                    <h2>Pasatiempos / Actividades</h2>
                    <ul>
                        <li *ngFor="let item of horli?.hobbies;">
                            {{ item.hobbie }}
                        </li>
                    </ul>
                </div>
                <div class="col-md-12">
                    <h2 class="int">Intereses</h2>
                    <p>
                        {{ horli?.interests }}
                    </p>
                </div>
                <div class="col-md-12 cities">
                    <h2>Ciudades en las que he vivido</h2>
                    <ul>
                        <li *ngFor="let item of horli?.cities;">
                            - {{ item.city }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 right" data-aos="fade-down" data-aos-easing="linear">
                <div class="shapes">
                    <ngx-masonry>
                        <div ngxMasonryItem class="masonry-item" *ngFor="let item of horli?.hobbiesPhotos">
                            <img [src]="item.url" alt="">
                        </div>
                    </ngx-masonry>
                    <!-- <ngx-masonry-gallery [width]='250' [images]='horli.hobbiesPhotos'></ngx-masonry-gallery> -->
                </div>
            </div>
        </div>
    </div>
</section>