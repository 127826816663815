<div class="home">
    <div class="container cont">
        <div>
            <h1>¡Revoluciona y mejora<br>tu hoja de Networking!</h1>
            <p>La introducción perfecta para una importante presentación, una presentación<br>
                de conexión y negocios con tus contactos.</p>
            <button class="btn first" [routerLink]="['/register']">Comencemos <i class="fa fa-long-arrow-right" aria-hidden="true"></i></button>
            <button class="btn btn-outline-secondary" (click)="scrollTo('how-works')">¿Cómo Funciona?</button>
        </div>
        <!-- <p class="hash">#GanarDando</p> -->
    </div>
</div>