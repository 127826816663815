import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from 'src/app/services/register/register.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  loader = true;
  input = false
  confirmForm
  user
  idUser

  constructor(
    private route: ActivatedRoute,
    private registerService: RegisterService,
    private router: Router,
    private fb: FormBuilder
  ) {
    
    

    this.confirmForm = this.fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,63}')
      ])]
    })

  }

  ngOnInit(): void {
    // if (this.router.url.split("/").length >= 2 &&
    //   this.router.url.split("/")[2] != "confirm" &&
    //   this.router.url.split("/")[2] != undefined
    // ) {
    //   this.router.navigateByUrl('page/not-found')
    // } else {
    //   this.idUser = this.router.url.split("/")[1];
    // }
    this.idUser = this.route.snapshot.paramMap.get("user");
    this.registerService.getHorliSub(this.idUser).subscribe((res:any) => {
      if (!res) {
        this.router.navigateByUrl('page/not-found')
      } else if (res.payIt == false) {
        this.horliNotPay(res);
      }
      else {
        this.userExist(res);
      }
    })
  }

  horliNotPay(res) {
    Swal.fire({
      icon: 'error',
      title: 'Anualidad vencida',
      text: res.name + " la anulidad de tu hoja de Networking ha vencido procede al pago para reactivar tu formato.",
      confirmButtonText: 'Entendido',
      customClass: {
        container: "modal-error"
      }
    }).then(() => {
      this.registerService.setReActive(true)
      this.router.navigateByUrl('/form/' + this.idUser)
    })
  }

  userExist(res) {
    this.user = res;
    this.registerService.setUserInfo(res);
    if (res.complete) {
      this.router.navigateByUrl('/presentation/' + this.idUser)
    } else {
      this.input = true;
      this.loader = false;
    }
  }

  confirmMail() {
    if (this.confirmForm.valid) {
      if (this.confirmForm.value.email == this.user.email) {
        this.registerService.setStatusComplete(true)
        this.router.navigateByUrl('/form/' + this.idUser)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "El correo no coincide con el registrado, verfique nuevamente por favor.",
          confirmButtonText: 'Entendido',
          customClass: {
            container: "modal-error"
          }
        }).then(() => {
          this.confirmForm.reset()
        })
      }
    }
  }

}
