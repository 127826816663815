<app-header></app-header>

<app-banner></app-banner>

<app-how-it-works></app-how-it-works>

<app-why-horli></app-why-horli>

<app-price-info></app-price-info>

<app-contact-help></app-contact-help>

<app-footer></app-footer>