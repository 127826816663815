import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from './services/register/register.service';
import Swal from 'sweetalert2';
import { MetatagsService } from './services/metatags/metatags.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Networking';
  ruta = this.router.url
  constructor(
    private route: ActivatedRoute,
    private registerService: RegisterService,
    private router: Router,
    public metatags: MetatagsService) {
  }

}
