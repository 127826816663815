<section id="benefits">
    <div class="container">
        <div class="row flex-xs">
            <div class="col-md-6 image" style="position: relative;">
                <img src="assets/img/4.png" class="img-fluid" alt="">
            </div>
            <div class="col-md-6 second">
                <div class="list">
                    <h1>¿Por qué revolucionar<br> tu Networking?</h1>
                    <ul>
                        <li>
                            <img src="assets/img/check.svg" alt="">
                            Destaca de tu presentación común.
                        </li>
                        <li>
                            <img src="assets/img/check.svg" alt="">
                            Accesibilidad instántanea.
                        </li>
                        <li>
                            <img src="assets/img/check.svg" alt="">
                            Incluye contenido multimedia.
                        </li>
                        <li>
                            <img src="assets/img/check.svg" alt="">
                            Visualmente diferente y atractivo.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>