import { Component, OnInit, Output, EventEmitter, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { FormService } from 'src/app/services/form/form.service';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit {

  @Output() next = new EventEmitter<string>();
  @ViewChild('modalPhoto') modalPhoto: TemplateRef<any>;
  personalForm: FormGroup;
  showForm = true;
  id;
  horliAux;
  loader: boolean = false;
  user: File[] = [];
  hobbies: File[] = [];
  imgs = [];
  userDB = [];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  modalRef: BsModalRef;
  currentStep
  scale = 1;
  zoom = 1;
  activeClick = false;
  activeClickSecond = false;
  transform: ImageTransform = {};
  tooltip;

  emptyInputCity;
  emptyInput = {
    0: false,
    1: false,
    2: false
  }
  skillsClean = []


  emptyInputSkill = {
    0: false,
    1: false,
    2: false
  }

  constructor(
    public fb: FormBuilder,
    public formService: FormService,
    private _route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService
  ) {

    this.id = this._route.snapshot.paramMap.get('user');

    this.personalForm = this.fb.group({
      aboutMe: ['', Validators.required],
      interests: ['', Validators.required],
      userPhoto: ['', Validators.required],
      hobbiesPhotos: [[], Validators.required],
      skills: this.fb.array([
        this.initSkills(),
        this.initSkills(),
        this.initSkills(),

      ]),
      hobbies: this.fb.array([
        this.initHobbies(),
        this.initHobbies(),
        this.initHobbies(),
      ]),
      cities: this.fb.array([
        this.initCities(),
        this.initCitiesNoRequired(),
        this.initCitiesNoRequired(),
      ]),
    })
  }


  valueChanged(e) {
    this.transform = {
      ...this.transform,
      scale: parseFloat(e)
    };
  }

  ngOnInit(): void {
    if (this.router.url != "/register") {
      this.id = this._route.snapshot.paramMap.get('user');
      this.formService.getByIDPromise(this.id).then((data: any) => {
        this.currentStep = data.currentStep;
        if (data.hobbiesPhotos) {
          data.hobbiesPhotos.forEach(elem => {
            this.formService.urlToBase64(elem.url).then((file: File) => {
              this.hobbies.push(file)
            })
          });
        }
        if (data.userPhoto) {
          this.userDB.push(data.userPhoto)
          this.formService.urlToBase64(data.userPhoto.url).then((file: File) => {
            this.user.push(file)
          })
        }
        this.horliAux = data;

        this.personalForm.controls.aboutMe.setValue(this.horliAux.aboutMe);
        this.personalForm.controls.interests.setValue(this.horliAux.interests);
        this.personalForm.controls.userPhoto.setValue(this.horliAux.userPhoto);
        this.personalForm.controls.hobbiesPhotos.setValue(this.horliAux.hobbiesPhotos);


        if (this.horliAux.userPhoto != undefined && this.horliAux.userPhoto != "") {
          this.personalForm.controls.userPhoto.clearValidators();
          this.personalForm.controls.userPhoto.updateValueAndValidity();
        }
        if (this.horliAux.hobbiesPhotos != undefined && this.horliAux.hobbiesPhotos != "") {
          this.personalForm.controls.hobbiesPhotos.clearValidators();
          this.personalForm.controls.hobbiesPhotos.updateValueAndValidity();
        }

        if (this.horliAux.skills != undefined) {
          if (this.horliAux.skills.length > this.getSkillsArray.length) {
            let aux = this.horliAux.skills.length - this.getSkillsArray.length;
            for (let index = 0; index < aux; index++) {
              this.addSkill()
            }
          }
          this.personalForm.controls.skills.setValue(this.horliAux.skills);
        }

        if (this.horliAux.hobbies != undefined) {
          if (this.horliAux.hobbies.length > this.getHobbiesArray.length) {
            let aux = this.horliAux.hobbies.length - this.getHobbiesArray.length;
            for (let index = 0; index < aux; index++) {
              this.addHobbie();
            }
          }
          if (this.horliAux.hobbies.length > 0) {
            this.personalForm.controls.hobbies.setValue(this.horliAux.hobbies);

          }
        }
        if (this.horliAux.cities != undefined) {
          if (this.horliAux.cities.length > this.getCitiesArray.length) {
            let aux = this.horliAux.cities.length - this.getCitiesArray.length;
            for (let index = 0; index < aux; index++) {
              this.addCity();
            }
            this.personalForm.controls.cities.setValue(this.horliAux.cities);
          } else {

            for (let index = 0; index < this.horliAux.cities.length; index++) {
              this.getCitiesArray.at(index).setValue(this.horliAux.cities[index])
            }
          }
        }
      });
    }
  }

  hasError = (controlName: string, errorName: string) => {
    return this.personalForm.controls[controlName].hasError(errorName);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg', backdrop: 'static' });
  }

  openTooltipModal(template: TemplateRef<any>, tooltipType) {
    this.tooltip = tooltipType;
    this.modalRef = this.modalService.show(template, { class: 'modal-xl', backdrop: 'static' });
  }

  closeModal(modalId?: number) {
    this.modalService.hide(modalId);
    this.user = []
  }

  closeTooltipModal() {
    this.modalService.hide();
  }

  fileChangeEvent(event: any): void {
    if (event.srcElement.files[0].size > 8000000) {
      this.imgSizeError()
      return;
    }
    this.imageChangedEvent = event;
    this.openModal(this.modalPhoto);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.user = []
    this.user.push(new File([this.formService.dataURItoBlob(event.base64)], 'Cualquiera', { type: 'image/jpeg' }));
    this.personalForm.controls.userPhoto.clearValidators();
    this.personalForm.controls.userPhoto.updateValueAndValidity();
  }
  imageLoaded(image?: HTMLImageElement) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  onSelectUserPhoto(event) {
    this.user = []
    this.user.push(...event.addedFiles);
    this.personalForm.controls.userPhoto.clearValidators();
    this.personalForm.controls.userPhoto.updateValueAndValidity();
  }

  onRemoveUserPhoto(event) {
    this.user.splice(this.user.indexOf(event), 1);
    // this.personalForm.controls.userPhoto.setValue("")
    this.personalForm.controls.userPhoto.setValidators([Validators.required]);
    this.personalForm.controls.userPhoto.updateValueAndValidity();
  }

  onSelectHobbiesPhotos(event) {
    if (event.rejectedFiles[0]) {
      event.rejectedFiles[0].reason == "size" ? this.imgSizeError() : null;
      return;
    }
    let alerta = false;
    event.addedFiles.forEach(element => {
      if (this.hobbies.length < 4) {
        this.hobbies.push(element);
      } else {
        alerta = true;
      }
    });
    if (this.hobbies.length > 0) {
      this.personalForm.controls.hobbiesPhotos.clearValidators();
      this.personalForm.controls.hobbiesPhotos.updateValueAndValidity();
    }
    if (alerta) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Excedió el número de imágenes',
        text: 'Sólo se agregaron las primeras 4 imágenes',
        confirmButtonText: 'Entendido',
        customClass: {
          container: "modal-warning"
        }
      })
    }
  }

  onRemoveHobbiesPhotos(event) {
    this.hobbies.splice(this.hobbies.indexOf(event), 1);
    if (this.hobbies.length == 0) {
      // this.personalForm.controls.hobbiesPhotos.setValue("")
      this.personalForm.controls.hobbiesPhotos.setValidators([Validators.required]);
      this.personalForm.controls.hobbiesPhotos.updateValueAndValidity();
    }
  }

  toggleForm(direct) {
    let cont = 0
    let empty = 3 - this.skillsClean.length;

    if (this.hasError('aboutMe', 'required') || this.hasError('userPhoto', 'required') || this.hasError('interests', 'required') || !this.personalForm.controls['skills'].valid) {
      this.activeClick = true;
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Favor de llenar los campos faltantes.',
        confirmButtonText: 'Entendido',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: "modal-warning"
        }
      }).then(e => {

        let index = 0;

        this.personalForm.controls.skills.value.forEach(element => {
          if (cont <= empty) {
            if (element.skill == "") {
              this.emptyInputSkill[index] = true;
              cont++
            }
          }
          index++;

        });
      })
    } else {
      if (direct == 'next') {
        this.saveChanges();
      }
      this.showForm = !this.showForm;
    }
  }

  initSkills() {
    return this.fb.group({
      skill: ['', Validators.required],
    });
  }

  initSkillsNoRequired() {
    return this.fb.group({
      skill: [''],
    });
  }

  get getSkillsArray() {
    return this.personalForm.get("skills") as FormArray;
  }

  addSkill() {
    const control = <FormArray>this.personalForm.controls['skills'];
    const skill = this.fb.group({
      skill: ['', Validators.required],
    });
    control.push(skill);
  }

  initHobbies() {
    return this.fb.group({
      hobbie: [''],
    });
  }

  get getHobbiesArray() {
    return this.personalForm.get("hobbies") as FormArray;
  }

  addHobbie() {
    const control = <FormArray>this.personalForm.controls['hobbies'];
    const hobbie = this.fb.group({
      hobbie: [''],
    });
    control.push(hobbie);
  }

  initCities() {
    return this.fb.group({
      city: [''],
    });
  }

  initCitiesNoRequired() {
    return this.fb.group({
      city: [''],
    });
  }

  get getCitiesArray() {
    return this.personalForm.get("cities") as FormArray;
  }

  addCity() {
    const control = <FormArray>this.personalForm.controls['cities'];
    const city = this.fb.group({
      city: [''],
    });
    control.push(city);
  }

  removeParam(indice, name) {
    const control = <FormArray>this.personalForm.controls[name];
    control.removeAt(indice);
  }

  onSubmit() {
    let citiesClean = []
    let hobbiesClean = []
    let cont = 0
    let cont2 = 0

    this.personalForm.controls.cities.value.forEach(element => {
      if (element.city != "") {
        citiesClean.push(element);
      }
    });

    this.personalForm.controls.hobbies.value.forEach(element => {
      if (element.hobbie != "") {
        hobbiesClean.push(element);
      }
    });

    let empty = 3 - hobbiesClean.length
    let empty2 = 1 - citiesClean.length

    if (this.personalForm.valid && citiesClean.length > 0 && hobbiesClean.length > 2) {
      this.loader = true;
      this.auxFunction().then(data => {
        if (this.userDB != []) {
          this.formService.cleanStorage(this.userDB)
        }
        this.uploadImage(this.user[0], this.id, this.personalForm.controls.userPhoto).then(data => {
          this.userDB = []
          this.userDB.push(data)
          this.formService.addPersonalInfo(this.id, this.personalForm.value, citiesClean, hobbiesClean, this.skillsClean);
          if (this.formService.getStep == 3) {
            this.formService.updateStep(this.id, this.formService.getStep + 1)
          }
          this.next.emit("next")
          this.loader = false;
          this.emptyInput[0] = false
          this.emptyInput[1] = false
          this.emptyInput[2] = false
          this.emptyInputCity = false
          this.emptyInputCity = -1;
        });
      });
    } else {
      
      this.activeClickSecond = true;
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Favor de llenar los campos faltantes.',
        confirmButtonText: 'Entendido',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          container: "modal-warning"
        }
      }).then(e => {
        let index = 0;
        this.personalForm.controls.hobbies.value.forEach(element => {
          if (cont <= empty) {
            if (element.hobbie == "") {
              this.emptyInput[index] = true;
              cont++
            }
          }
          index++;
        });

        this.personalForm.controls.cities.value.forEach(element => {
          if (element.city == "") {
            this.emptyInputCity = 0;
          }
        });
      })
    }
  }

  saveChanges() {
    this.loader = true;

    let citiesClean = [];
    let hobbiesClean = [];
    this.skillsClean = [];

    this.personalForm.controls.skills.value.forEach(element => {
      if (element.skill != "") {
        this.skillsClean.push(element);
      }
    });

    this.personalForm.controls.cities.value.forEach(element => {
      if (element.city != "") {
        citiesClean.push(element);
      }
    });

    this.personalForm.controls.hobbies.value.forEach(element => {
      if (element.hobbie != "") {
        hobbiesClean.push(element);
      }
    });

    this.auxFunction().then(data => {
      if (this.userDB != []) {
        this.formService.cleanStorage(this.userDB)
      }
      if (this.personalForm.controls.userPhoto) { }

      this.uploadImage(this.user[0], this.id, this.personalForm.controls.userPhoto).then(data => {
        this.userDB = []
        this.userDB.push(data)
        this.formService.addPersonalInfo(this.id, this.personalForm.value, citiesClean, hobbiesClean, this.skillsClean).then(data => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Cambios guardados',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              container: "modal-warning"
            }
          }).then(() => {
            this.loader = false;
          })
        });
      });
    });
  }

  uploadImage(file, id, control) {
    return new Promise<any>((resolve, reject) => {
      if (file != undefined) {
        this.formService.uploadImage(file, id).then(data => {
          control.setValue(data);
          resolve(data)
        })
      } else {
        resolve("vacio")
      }
    })
  }

  async uploadArrayImage(id, file) {
    await new Promise<void>((resolve, reject) => {
      this.formService.uploadImage(file, id).then(data => {
        this.imgs.push(data)
        resolve()
      })
    })
  }

  auxFunction() {
    return new Promise<any>((resolve, reject) => {
      this.imgs = []
      var promises = []
      var i = 0
      if (this.hobbies.length > 0) {
        if (this.personalForm.value.hobbiesPhotos) {
          this.formService.cleanStorage(this.personalForm.value.hobbiesPhotos)
        }
        for (let file of this.hobbies) {
          promises.push(this.uploadArrayImage(this.id, file))
          i++
        }
        Promise.all(promises).then(val => {
          this.personalForm.controls.hobbiesPhotos.setValue(this.imgs);
          resolve(this.imgs)

        })
      } else {
        resolve("vacio")
      }

    })
  }

  cambioError(index, name) {
    switch (name) {
      case 'hobbies':
        this.emptyInput[index] = false;
        break;

      case 'cities':
        this.emptyInputCity = -1;
        break;

      case 'skills':
        this.emptyInputSkill[index] = false;
        break;


      default:
        break;
    }

  }

  imgSizeError() {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Tamaño excedido',
      text: 'La imagen excede el tamaño permitido (8MB)',
      confirmButtonText: 'Entendido',
      customClass: {
        container: "modal-warning"
      }
    })
  }

}