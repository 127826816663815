import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as AOS from 'aos';
import { FormService } from 'src/app/services/form/form.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MetatagsService } from 'src/app/services/metatags/metatags.service';

@Component({
  selector: 'app-my-presentation',
  templateUrl: './my-presentation.component.html',
  styleUrls: ['./my-presentation.component.scss']
})
export class MyPresentationComponent implements OnInit {

  id;
  horli;
  loader: boolean = true;
  ruta = this.router.url

  @Inject(PLATFORM_ID) private platformId: any

  constructor(public formService: FormService, private _route: ActivatedRoute, private router: Router, public metatags: MetatagsService) {

    if (isPlatformBrowser(this.platformId)) {
      document.body.style.overflow = 'hidden';
    }

    this.id = this._route.snapshot.paramMap.get('user');
    this.formService.getByIDPromise(this.id).then((data: any) => {
      if (data == undefined) {
        this.router.navigateByUrl('/page/not-found')
      }
      if (data.payIt == false || data.complete == false) {
        this.router.navigateByUrl('/' + this.id + '/confirm')
      }
      this.horli = data;
      this.loader = false;
      if (isPlatformBrowser(this.platformId)) {
        document.body.style.overflow = '';
      }
      let rutasplit = this.ruta.split("/")
      metatags.updateMetaTags(rutasplit[1], data, rutasplit[2]);
    });
  }


  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      AOS.init({
        disable: 'mobile',
        duration: 2000, // values from 0 to 3000, with step 50ms
      });
    }
  }

}
