import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { FormService } from 'src/app/services/form/form.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-step-five',
  templateUrl: './step-five.component.html',
  styleUrls: ['./step-five.component.scss']
})
export class StepFiveComponent implements OnInit {

  @Output() next = new EventEmitter<string>();
  @Output() back = new EventEmitter<string>();
  @ViewChild("company") company;
  professionalForm: FormGroup;
  showForm = true;
  id;
  horliAux;
  loader: boolean = false;
  logo: File[] = [];
  image: File[] = [];
  logoDB = []
  imageDB = []
  tooltip;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  modalRef
  activeClick = false;
  activeClickSecond = false;
  currentStep
  transform: ImageTransform = {};
  oldJobsClean = [];
  emptyInputJob
  emptyInputService = {
    0: false,
    1: false,
    2: false
  }

  emptyInputReference = {
    0: false,
    1: false,
    2: false
  }

  emptyInputClient = {
    0: false,
    1: false,
    2: false
  }

  emptyInputContact = {
    0: false,
    1: false,
    2: false
  }

  constructor(
    public fb: FormBuilder,
    public formService: FormService,
    private _route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService
  ) {

    this.professionalForm = this.fb.group({
      whatIDo: [''],
      personalGoals: [''],
      professionalGoals: [''],
      services: this.fb.array([
        this.initServices(),
        this.initServices(),
        this.initServices(),
      ]),
      contacts: this.fb.array([
        this.initContacts(),
        this.initContacts(),
        this.initContacts(),
      ]),
      clients: this.fb.array([
        this.initClients(),
        this.initClients(),
        this.initClients(),
      ]),
      oldJobs: this.fb.array([
        this.initOldJobs(),
        this.initOldJobs(),
        this.initOldJobs(),
      ]),
      companyReference: this.fb.array([
        this.initReferences(),
        this.initReferences(),
        this.initReferences(),
      ]),
      companyName: ['', Validators.required],
      companyType: ['', Validators.required],
      companyDescription: ['', Validators.required],
      // companyReference: ['', Validators.required],
      companyImage: ['', Validators.required],
      companyLogo: ['', Validators.required],
      finishForm: [false]
    })
    this.id = this._route.snapshot.paramMap.get('user');
  }

  hasError = (controlName: string, errorName: string) => {
    return this.professionalForm.controls[controlName].hasError(errorName);
  }

  valueChanged(e) {
    this.transform = {
      ...this.transform,
      scale: parseFloat(e)
    };
  }

  ngOnInit(): void {
    if (this.router.url != "/register") {
      this.id = this._route.snapshot.paramMap.get('user');
      this.formService.getByIDPromise(this.id).then((data: any) => {
        this.currentStep = data.currentStep;
        this.horliAux = data;


        if (data.companyImage) {
          this.imageDB.push(data.companyImage);
          this.formService.urlToBase64(data.companyImage.url).then((file: File) => {
            this.image.push(file);
          })
        }

        if (data.companyLogo) {
          this.logoDB.push(data.companyLogo);
          this.formService.urlToBase64(data.companyLogo.url).then((file: File) => {
            this.logo.push(file);
          })
        }

        this.professionalForm.controls.whatIDo.setValue(this.horliAux.whatIDo != undefined ? this.horliAux.whatIDo : "");
        this.professionalForm.controls.personalGoals.setValue(this.horliAux.personalGoals != undefined ? this.horliAux.personalGoals : "");
        this.professionalForm.controls.professionalGoals.setValue(this.horliAux.professionalGoals != undefined ? this.horliAux.professionalGoals : "");
        this.professionalForm.controls.companyName.setValue(this.horliAux.companyName != undefined ? this.horliAux.companyName : "");
        this.professionalForm.controls.companyType.setValue(this.horliAux.companyType != undefined ? this.horliAux.companyType : "");
        this.professionalForm.controls.companyDescription.setValue(this.horliAux.companyDescription != undefined ? this.horliAux.companyDescription : "");
        this.professionalForm.controls.companyImage.setValue(this.horliAux.companyImage != undefined ? this.horliAux.companyImage : "");
        this.professionalForm.controls.companyLogo.setValue(this.horliAux.companyLogo != undefined ? this.horliAux.companyLogo : "");

        if (this.horliAux.contacts != undefined && this.horliAux.contacts.length != 0) {
          if (this.horliAux.contacts.length > this.getContacsArray.length) {
            let aux = this.horliAux.contacts.length - this.getContacsArray.length;
            for (let index = 0; index < aux; index++) {
              this.addContact()
            }
          }
          this.professionalForm.controls.contacts.setValue(this.horliAux.contacts);
        }

        if (this.horliAux.clients != undefined && this.horliAux.clients.length != 0) {
          if (this.horliAux.clients.length > this.getClientsArray.length) {
            let aux = this.horliAux.clients.length - this.getClientsArray.length;
            for (let index = 0; index < aux; index++) {
              this.addClient()
            }
          }
          this.professionalForm.controls.clients.setValue(this.horliAux.clients);
        }

        if (this.horliAux.companyReference != undefined && this.horliAux.companyReference.length != 0) {
          if (this.horliAux.companyReference.length > this.getReferencesArray.length) {
            let aux = this.horliAux.companyReference.length - this.getReferencesArray.length;
            for (let index = 0; index < aux; index++) {
              this.addReferences()
            }
          }
          this.professionalForm.controls.companyReference.setValue(this.horliAux.companyReference);
        }

        if (this.horliAux.services != undefined && this.horliAux.services.length != 0) {
          if (this.horliAux.services.length > this.getServicesArray.length) {
            let aux = this.horliAux.services.length - this.getServicesArray.length;
            for (let index = 0; index < aux; index++) {
              this.addService()
            }
          }
          this.professionalForm.controls.services.setValue(this.horliAux.services);
        }

        if (this.horliAux.oldJobs != undefined && this.horliAux.oldJobs.length != 0) {
          if (this.horliAux.oldJobs.length > this.getOldJobsArray.length) {
            let aux = this.horliAux.oldJobs.length - this.getOldJobsArray.length;
            for (let index = 0; index < aux; index++) {
              this.addOldJob();
            }
          } else if (this.horliAux.oldJobs.length > 0 && this.horliAux.oldJobs.length < this.getOldJobsArray.length) {
            for (let i = this.getOldJobsArray.length; i > this.horliAux.oldJobs.length; i--) {
              this.removeParam(i - 1, 'oldJobs')
            }
          }
          this.professionalForm.controls.oldJobs.setValue(this.horliAux.oldJobs);
        }

      });
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { id: 1, class: 'modal-lg', backdrop: 'static' });
  }

  closeModal(modalId?: number) {
    this.modalService.hide(modalId);
    this.image = []
  }

  openTooltipModal(template: TemplateRef<any>, tooltipType) {
    this.tooltip = tooltipType;
    this.modalRef = this.modalService.show(template, { class: 'modal-xl', backdrop: 'static' });
  }

  fileChangeEvent(event: any): void {
    if (event.srcElement.files[0].size > 8000000) {
      this.imgSizeError()
      return;
    }
    this.imageChangedEvent = event;
    this.openModal(this.company)
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.image = []
    this.image.push(new File([this.formService.dataURItoBlob(event.base64)], 'Cualquiera', { type: 'image/jpeg' }));
    this.professionalForm.controls.companyImage.clearValidators();
    this.professionalForm.controls.companyImage.updateValueAndValidity();
  }

  onSelectLogo(event) {
    if (event.rejectedFiles[0]) {
      event.rejectedFiles[0].reason == "size" ? this.imgSizeError() : null;
      return;
    }
    this.logo = []
    this.logo.push(...event.addedFiles);
    this.professionalForm.controls.companyLogo.clearValidators();
    this.professionalForm.controls.companyLogo.updateValueAndValidity();
  }

  onRemoveLogo(event) {
    this.logo.splice(this.logo.indexOf(event), 1);
    this.professionalForm.controls.companyLogo.setValue("")
    this.professionalForm.controls.companyLogo.setValidators([Validators.required]);
    this.professionalForm.controls.companyLogo.updateValueAndValidity();
  }

  onSelectImage(event) {
    this.image = []
    this.image.push(...event.addedFiles);
    this.professionalForm.controls.companyImage.clearValidators();
    this.professionalForm.controls.companyImage.updateValueAndValidity();
  }

  onRemoveImage(event) {
    this.image.splice(this.image.indexOf(event), 1);
    this.professionalForm.controls.companyImage.setValue("")
    this.professionalForm.controls.companyImage.setValidators([Validators.required]);
    this.professionalForm.controls.companyImage.updateValueAndValidity();
  }

  toggleForm(direct) {

    if (this.hasError('companyName', 'required') || this.hasError('companyType', 'required') || this.professionalForm.controls['oldJobs'].status == 'INVALID' ||
      this.hasError('companyDescription', 'required') || this.hasError('companyLogo', 'required') || this.hasError('companyImage', 'required') || this.oldJobsClean.length >= 1) {
      this.activeClick = true;
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Favor de llenar los campos faltantes.',
        confirmButtonText: 'Entendido',
        customClass: {
          container: "modal-warning"
        }
      }).then(e => {

        this.professionalForm.controls.oldJobs.value.forEach(element => {
          if (element.oldJob == "") {
            this.emptyInputJob = 0;
          }
        });
      })
      return;
    } else {
      this.emptyInputJob = -1;
      this.showForm = !this.showForm;
    }
    if (direct == 'next') {
      this.saveChanges();
    }
  }

  goForward() {
    this.next.emit("next")
  }

  goBack() {
    this.back.emit("back")
  }

  initServices() {
    return this.fb.group({
      service: [''],
    });
  }

  get getServicesArray() {
    return this.professionalForm.get("services") as FormArray;
  }

  addService() {
    const control = <FormArray>this.professionalForm.controls['services'];
    const service = this.fb.group({
      service: [''],
    });
    control.push(service);
  }

  initContacts() {
    return this.fb.group({
      contact: [''],
    });
  }

  get getContacsArray() {
    return this.professionalForm.get("contacts") as FormArray;
  }

  addContact() {
    const control = <FormArray>this.professionalForm.controls['contacts'];
    const contact = this.fb.group({
      contact: [''],
    });
    control.push(contact);
  }

  initReferences() {
    return this.fb.group({
      reference: ['']
    });
  }

  get getReferencesArray() {
    return this.professionalForm.get("companyReference") as FormArray;
  }

  addReferences() {
    const control = <FormArray>this.professionalForm.controls['companyReference'];
    const reference = this.fb.group({
      reference: [''],
    });
    control.push(reference);
  }

  initClients() {
    return this.fb.group({
      client: [''],
    });
  }

  get getClientsArray() {
    return this.professionalForm.get("clients") as FormArray;
  }

  addClient() {
    const control = <FormArray>this.professionalForm.controls['clients'];
    const clients = this.fb.group({
      clients: [''],
    });
    control.push(clients);
  }

  initOldJobs() {
    return this.fb.group({
      oldJob: ['', Validators.required],
    });
  }

  get getOldJobsArray() {
    return this.professionalForm.get("oldJobs") as FormArray;
  }

  addOldJob() {
    const control = <FormArray>this.professionalForm.controls['oldJobs'];
    const oldJob = this.fb.group({
      oldJob: [''],
    });
    control.push(oldJob);
  }

  removeParam(indice, name) {
    const control = <FormArray>this.professionalForm.controls[name];
    control.removeAt(indice);
  }

  onSubmit() {
    let serviceClean = []
    let contactClean = []
    let referenceClean = []
    let clientClean = []
    let cont = 0
    let cont2 = 0
    let cont3 = 0
    let cont4 = 0

    this.oldJobsClean = []

    this.professionalForm.controls.oldJobs.value.forEach(element => {
      if (element.oldJob != "") {
        this.oldJobsClean.push(element);
      }
    });

    this.professionalForm.controls.services.value.forEach(element => {
      if (element.service != "") {
        serviceClean.push(element);
      }
    });

    this.professionalForm.controls.companyReference.value.forEach(element => {
      if (element.reference != "") {
        referenceClean.push(element);
      }
    });

    this.professionalForm.controls.contacts.value.forEach(element => {
      if (element.contact != "") {
        contactClean.push(element);
      }
    });

    this.professionalForm.controls.clients.value.forEach(element => {
      if (element.client != "") {
        clientClean.push(element);
      }
    });

    let empty1 = 3 - serviceClean.length
    let empty2 = 3 - referenceClean.length
    let empty3 = 3 - clientClean.length
    let empty4 = 3 - contactClean.length


    if (this.professionalForm.valid && serviceClean.length > 2
      && contactClean.length > 2 && referenceClean.length > 2 && clientClean.length > 2) {
      this.loader = true;
      if (this.logoDB.length != 0) {
        this.formService.cleanStorage(this.logoDB);
      }
      if (this.imageDB.length != 0) {
        this.formService.cleanStorage(this.imageDB);
      }
      this.uploadImage(this.logo[0], this.id, this.professionalForm.controls.companyLogo).then(data => {
        this.logoDB = [];
        this.logoDB.push(data);
        this.uploadImage(this.image[0], this.id, this.professionalForm.controls.companyImage).then(data => {
          this.imageDB = [];
          this.imageDB.push(data);
          this.formService.addProfessionalInfo(this.id, this.professionalForm.value, this.oldJobsClean, serviceClean, referenceClean, clientClean, contactClean).then(data => {
            if (this.formService.getStep == 5) {
              this.formService.updateStep(this.id, this.formService.getStep + 1)
            }
            this.professionalForm.controls.finishForm.setValue(true);
            this.loader = false;
            this.next.emit("next")
            this.loader = false;
          });
        });
      });
    } else {
      this.activeClickSecond = true;
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Favor de llenar los campos faltantes.',
        confirmButtonText: 'Entendido',
        customClass: {
          container: "modal-warning"
        }
      }).then(e => {

        let index = 0;
        this.professionalForm.controls.services.value.forEach(element => {
          if (cont <= empty1) {
            if (element.service == "") {
              this.emptyInputService[index] = true;
              cont++
            }
          }
          index++;
        });


        let index2 = 0

        this.professionalForm.controls.companyReference.value.forEach(element => {
          if (cont2 <= empty2) {
            if (element.reference == "") {
              this.emptyInputReference[index2] = true;
              cont2++;
            }
          }
          index2++;
        });

        let index3 = 0

        this.professionalForm.controls.clients.value.forEach(element => {
          if (cont3 <= empty3) {
            if (element.client == "") {
              this.emptyInputClient[index3] = true;
              cont3++;
            }
          }
          index3++;
        });


        let index4 = 0

        this.professionalForm.controls.contacts.value.forEach(element => {
          if (cont4 <= empty4) {
            if (element.contact == "") {
              this.emptyInputContact[index4] = true;
              cont4++
            }
          }
          index4++;
        });
        index = 0;


      })
      // alert("Favor de llenar los campos faltantes.");
    }
  }

  saveChanges() {

    let serviceClean = []
    let contactClean = []
    let referenceClean = []
    let clientClean = []
    let cont = 0
    let cont2 = 0
    let cont3 = 0
    let cont4 = 0

    this.oldJobsClean = []

    this.professionalForm.controls.oldJobs.value.forEach(element => {
      if (element.oldJob != "") {
        this.oldJobsClean.push(element);
      }
    });

    this.professionalForm.controls.services.value.forEach(element => {
      if (element.service != "") {
        serviceClean.push(element);
      }
    });

    this.professionalForm.controls.companyReference.value.forEach(element => {
      if (element.reference != "") {
        referenceClean.push(element);
      }
    });

    this.professionalForm.controls.contacts.value.forEach(element => {
      if (element.contact != "") {
        contactClean.push(element);
      }
    });

    this.professionalForm.controls.clients.value.forEach(element => {
      if (element.client != "") {
        clientClean.push(element);
      }
    });

    let empty1 = 3 - serviceClean.length
    let empty2 = 3 - referenceClean.length
    let empty3 = 3 - clientClean.length
    let empty4 = 3 - contactClean.length

    this.loader = true;
    if (this.logoDB.length != 0) {
      this.formService.cleanStorage(this.logoDB);
    }
    if (this.imageDB.length != 0) {
      this.formService.cleanStorage(this.imageDB);
    }
    this.uploadImage(this.logo[0], this.id, this.professionalForm.controls.companyLogo).then(data => {
      this.logoDB = [];
      this.logoDB.push(data);
      this.uploadImage(this.image[0], this.id, this.professionalForm.controls.companyImage).then(data => {
        this.imageDB = [];
        this.imageDB.push(data);
        this.formService.addProfessionalInfo(this.id, this.professionalForm.value, this.oldJobsClean, serviceClean, referenceClean, clientClean, contactClean).then(data => {
          this.loader = false;
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Cambios guardados',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              container: "modal-warning"
            }
          }).then(() => {
            this.loader = false;


            let index = 0;
            this.professionalForm.controls.services.value.forEach(element => {
              if (cont <= empty1) {
                if (element.service == "") {
                  this.emptyInputService[index] = true;
                  cont++
                }
              }
              index++;
            });


            let index2 = 0

            this.professionalForm.controls.companyReference.value.forEach(element => {
              if (cont2 <= empty2) {
                if (element.reference == "") {
                  this.emptyInputReference[index2] = true;
                  cont2++;
                }
              }
              index2++;
            });

            let index3 = 0

            this.professionalForm.controls.clients.value.forEach(element => {
              if (cont3 <= empty3) {
                if (element.client == "") {
                  this.emptyInputClient[index3] = true;
                  cont3++;
                }
              }
              index3++;
            });


            let index4 = 0

            this.professionalForm.controls.contacts.value.forEach(element => {
              if (cont4 <= empty4) {
                if (element.contact == "") {
                  this.emptyInputContact[index4] = true;
                  cont4++
                }
              }
              index4++;
            });
            index = 0;
          })
        });
      });
    });
  }

  uploadImage(file, id, control) {
    return new Promise<any>((resolve, reject) => {
      if (file != undefined) {
        this.formService.uploadImage(file, id).then(data => {
          control.setValue(data);
          resolve(data)
        })
      } else {
        resolve("vacio")
      }
    })
  }

  cambioError(index, name) {
    switch (name) {
      case 'services':
        this.emptyInputService[index] = false;
        break;
      case 'references':
        this.emptyInputReference[index] = false;
        break;

      case 'clients':
        this.emptyInputClient[index] = false;
        break;

      case 'contacts':
        this.emptyInputContact[index] = false;
        break;
      default:
        break;
    }

  }

  imgSizeError() {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Tamaño excedido',
      text: 'La imagen excede el tamaño permitido (8MB)',
      confirmButtonText: 'Entendido',
      customClass: {
        container: "modal-warning"
      }
    })
  }

}