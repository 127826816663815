<section>

    <ng-container *ngIf="step == 'one'">
        <div class="container">
            <h1>Información de Contacto</h1>
            <p>¡<strong>Gracias</strong> por confiar y comenzar el proceso de tu nueva hoja de Networking! Para
                continuar con el
                formulario favor de realizar tu pago para hacerte llegar a tu correo toda la información.</p>
            <div class="row payment">
                <div class="col-md-6 col-lg-7 card-info">
                    <div class="form">
                        <form [formGroup]="payForm">
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="name" class="col-form-label">TITULAR DE LA TARJETA</label>
                                    <div>
                                        <input formControlName="name" type="text" class="form-control" id="namecard">
                                    </div>
                                </div>
                                <div class="form-group col-md-12">
                                    <div class="row">
                                        <div [ngClass]="{'col-md-10': cardType !='', 'col-md-12': cardType ==''}">
                                            <label for="card" class="col-form-label">NÚMERO DE TARJETA</label>
                                            <div>
                                                <input formControlName="card" mask="{{cardMask}}" type="text"
                                                    class="form-control" id="card"
                                                    (keyup)="getBrand($event.target.value)">
                                            </div>
                                        </div>
                                        <div class="col-md-2 card-type-img" *ngIf="cardType !=''">
                                            <img src="assets/img/card-types/visa.svg" alt="" *ngIf="cardType == 'visa'">
                                            <img src="assets/img/card-types/amex.svg" alt="" *ngIf="cardType == 'amex'">
                                            <img src="assets/img/card-types/mc.png" alt=""
                                                *ngIf="cardType == 'mastercard'">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-6">
                                    <label for="date" class="col-form-label">FECHA DE EXPIRACIÓN</label>
                                    <div class="row">
                                        <div class="col-md-6 col-6" style="padding-right:5px;">
                                            <input formControlName="month" mask="00" placeholder="MM" type="text"
                                                class="form-control" id="month">
                                        </div>
                                        <div class="col-md-6 col-6" style="padding-left: 5px;">
                                            <input formControlName="year" placeholder="YY" mask="00" type="text"
                                                class="form-control" id="year">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-6">
                                    <label for="cvc" class="col-form-label">CÓDIGO DE SEGURIDAD</label>
                                    <div>
                                        <input formControlName="cvv" mask="{{cardCVVMask}}" type="text"
                                            class="form-control" id="cvc">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-5 col-md-6 price">
                    <h2>RESUMEN DE COMPRA</h2>
                    <div class="square">
                        <div class="details">
                            <h3>Producto</h3>
                            <p>Hoja Networking Digital por 1 año.</p>
                            <div>
                                <p>MXN399.00</p>
                                <p>MXN499.00</p>
                            </div>
                        </div>

                        <div class="col-md-12 acepted-cards text-right">
                            <img src="assets/img/card-types/visa.svg">
                            <img src="assets/img/card-types/amex.svg">
                            <img src="assets/img/card-types/mc.png">
                        </div>

                        <div class="total">
                            <h3>Total a Pagar</h3>
                            <p>MXN399.00</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons row">
                <div class="col-md-7 col-6">
                    <button (click)="goBack()" type="button"><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                        Volver</button>
                </div>
                <div class="col-md-5 col-6">
                    <button (click)="doPay()" class="btn-pay" [disabled]="!payForm.valid" type="button">Pagar</button>
                </div>
                <!-- [disabled]="!payForm.valid" -->
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step == 'two'">
        <div class="container success">
            <div class="content">
                <img src="assets/img/check.jpg" alt="">
                <h1>¡Tu pago se ha realizado con éxito!</h1>
                <p>
                    ¡<strong>Gracias</strong> por realizar tu pago! <br>
                    Toda la información ha sido enviada al correo:<br>
                    <strong>{{user.email}}</strong>
                </p>
                <button (click)="goToThree()">Continuar <img src="assets/img/arrow_white.svg" alt=""></button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step == 'three'">
        <div class="container next-step">
            <div class="content">
                <h1>¡FELICIDADES!</h1>
                <p class="short">Ya puedes continuar con el formulario<br> de tu nueva hoja de Networking</p>
                <p>Éste es el link que te servirá para compartir y acceder a tu nueva hoja de Networking, también te
                    servirá en caso de que no completes el formulario</p>
                <a href="{{user.url}}">{{user.url}}</a>
                <button (click)="goToConfirm()">Continuar <img src="assets/img/arrow_white.svg" alt=""></button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="loader">
        <div class="loader">
            <div class="spinner-border text-danger" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </ng-container>

</section>